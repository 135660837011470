import { ButtonTo, InputContainer, InputText } from "../StyledAuth";
import { MuiButton, CodeAuthComp } from "~/Components/Form";
import { Form, Formik } from "formik";
import { formikProps } from "./data";
import { useEffect, useState } from "react";
import { POST_REQUEST } from "~/HooksApi/useApi";
import { USeInsertData } from "~/HookAxios/postData";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useModalContext } from "~/Provider/ModalProvider";
import HookVerification from "~/hooks/hookAuth/hook-Verfication";
import { useDeferredValue } from "react";
const VerifyCodeForm = () => {
  const { reVerficaton, StutusResend } = HookVerification();
  const id = useSelector((state) => state.Auth.userInfo.data.id);
  const [Status, setStatus] = useState(false);
  const [code, setCode] = useState("");
  const [errorrr, setErrorrr] = useState(true);
  const { closeverifyCode } = useModalContext();
  const onChange = (data) => {
    setCode(data);
  };
  const dataCode = useDeferredValue(code);
  useEffect(() => {
    if (dataCode !== "") {
      if (dataCode.includes("-")) {
        setErrorrr(true);
      } else {
        setErrorrr(false);
      }
    }
  }, [dataCode]);
  const onSubmit = (values) => {
    if (!errorrr) {
      setStatus(true);
      VefiyCode({
        code,
        id,
      });
    }
  };

  const {
    mutate: VefiyCode,
    status: VefiyCodeStatus,
    data: VerfyCodeData,
    error: VefiyCoderror,
  } = POST_REQUEST({
    request: (data) => USeInsertData("verification", data),
    handelOnSettledError,
    handelOnSettledData,
  });
  function handelOnSettledError(error) {
    setStatus(false);
  }
  function handelOnSettledData(data) {
    closeverifyCode();
    setStatus(false);
  }
  return (
    <Formik
      initialValues={formikProps.initialValues}
      // validationSchema={formikProps.validationSchema}
      onSubmit={onSubmit}
      render={({ handleChange, handleBlur, values, errors, touched }) => (
        <Form style={{ width: "100%" }}>
          <div style={{overflow:'hidden'}}>
            <InputText>أرسلنا لك رمز مكون من 6 أرقام</InputText>
            <CodeAuthComp
              onChange={(e) => [handleChange(e), onChange(e)]}
              onBlur={handleBlur}
              length={6}
              value={values.code}
              error={errorrr}
              errorText={errorrr && touched.code ? "يرجى ادخال الكود" : null}
              autoFocus
              name="code"
            />
          </div>
          <InputContainer style={{ marginTop: "10px" }}>
            <InputText>
              {!Status ? (
                StutusResend ? (
                  <CircularProgress
                    sx={{ alignSelf: "center", height: "10px" }}
                  />
                ) : (
                  <ButtonTo onClick={reVerficaton}>
                    {" "}
                    <span style={{ color: "Gray", margin: "0 0 0 4px" }}>
                      لم يصلك الرمز؟{" "}
                    </span>
                    إعادة إرسال
                  </ButtonTo>
                )
              ) : null}
              <ButtonTo onClick={closeverifyCode}> </ButtonTo>
            </InputText>
          </InputContainer>
          <InputContainer
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InputText></InputText>

            {Status ? (
              <CircularProgress sx={{ alignSelf: "center" }} />
            ) : (
              <MuiButton text="تحقق" width="60%" height="40px" />
            )}
          </InputContainer>
        </Form>
      )}
    />
  );
};

export default VerifyCodeForm;
