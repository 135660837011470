import { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../app/features";
import {
  setFalsAnswerList,
  setResetQuizGroup,
  setTruthAnswerList,
} from "../app/features/CourseTest/store/courseTestSlice";

export const ModalContext = createContext();
export const useModalContext = () => useContext(ModalContext);
export const ModalContextProvider = ({ children }) => {
  const backToHome = useSelector((state) => state.Auth.userInfo.backToHome);

  const [rigester, setRigester] = useState(false);
  const [forgetPass, setForgetPass] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [login, setLogin] = useState(false);
  const [verify, setVerify] = useState(false);
  const [optionsOther, setOptionsOther] = useState(false);
  const [changedPass, setChangedPass] = useState(false);
  const [changedPassProfile, setChangedPassProfile] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [verifyCodeForgetPass, setVerifyCodeForgetPass] = useState(false);
  const [verifyCodeForgetPasswithEmail, setVerifyCodeForgetPasswithEmail] =
    useState(false);
  const [cahngeUserImge, setCahngeUserImge] = useState(false);
  const [LockedAccess, setLockedAccess] = useState(false);
  const [LockedAccessDepartment, setLockedAccessDepartment] = useState(false);
  const [ReaultTest, setReaultTest] = useState(false);
  const [FetshData, setFetshData] = useState(false);
  const [paypoints, setPaypoints] = useState(false);
  const [EndSes, setEndSes] = useState(false);
  const [approve, setApprove] = useState(false);
  const [open, setOpen] = useState(false);
  const handelSetFetch = () => setFetshData(!FetshData);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenApprove = () => setApprove(true);
  const handleCloseApprove = () => setApprove(false);
  const handleOpenEndSes = () => setEndSes(true);
  const handleCloseEndSes = () => setEndSes(false);

  const openpaypoints = () => {
    setPaypoints(true);
  };
  const Closepaypoints = () => setPaypoints(false);

  const openLockedAccessDepartment = () => {
    setLockedAccessDepartment(true);
  };
  const CloseReaultTest = () => {
    setReaultTest(false);
    store.dispatch(setFalsAnswerList([]));
    store.dispatch(setTruthAnswerList([]));
    store.dispatch(setResetQuizGroup(true));
  };

  const openReaultTest = () => {
    setReaultTest(true);
  };
  const CloseLockedAccessDepartment = () => setLockedAccessDepartment(false);

  const openLockedAcsses = () => {
    setLockedAccess(true);
  };

  const CloseLockedAccess = () => setLockedAccess(false);
  const openRigester = () => {
    CloseLogin({ stay: false });
    setRigester(true);
  };

  const CloseRigester = () => setRigester(false);
  const CloseLogin = ({ stay }) => {
    if (backToHome && !stay) {
      window.location.replace("/");
    }
    setLogin(false);
  };
  const openLogin = () => {
    setNewPass(false);
    setRigester(false);
    setLogin(true);
    setChangedPass(false);
    setForgetPass(false);
    closeOptionsOther();
  };
  const submitLogin = () => {
    setVerify(true);
    CloseLogin({ stay: true });
  };
  const CloseVerify = () => setVerify(false);
  const openVerify = () => {
    setVerify(true);
    setForgetPass(false);
  };
  const SubmitVerify = () => {
    setVerify(false);
    setNewPass(true);
  };
  const CloseNewPass = () => {
    setNewPass(false);
    openLogin();
  };
  const openNewPass = () => {
    setNewPass(true);
  };
  const CloseForgetPass = () => {
    setForgetPass(false);
    setChangedPassProfile(false);
  };
  const openForgetPass = () => {
    setForgetPass(true);
    CloseLogin();
    setChangedPassProfile(false);
  };
  const closeOptionsOther = () => {
    setOptionsOther(false);
  };
  const openOptionsOther = () => {
    setOptionsOther(true);
    setVerify(false);
  };
  const closeChangedPass = () => setChangedPass(false);
  const openChangedPass = () => {
    setChangedPass(true);
    setNewPass(false);
  };
  const closeChangedPassProfile = () => setChangedPassProfile(false);
  const openChangedPassProfile = () => {
    setChangedPassProfile(true);
  };
  const openverifyCode = () => setVerifyCode(true);
  const closeverifyCode = () => {
    setVerifyCode(false);
  };
  const openVerifyCodeForgetPass = () => setVerifyCodeForgetPass(true);
  const closeverifyCodeForgetPass = () => {
    setVerifyCodeForgetPass(false);
  };
  const openverifyCodeForgetPasswithEmail = () =>
    setVerifyCodeForgetPasswithEmail(true);
  const closeverifyCodeForgetPasswithEmail = () => {
    setVerifyCodeForgetPasswithEmail(false);
  };
  const opencahngeUserImge = () => {
    setCahngeUserImge(true);
    CloseRigester();
  };
  const closecahngeUserImge = () => {
    setCahngeUserImge(false);
    openverifyCode();
  };
  return (
    <ModalContext.Provider
      value={{
        rigester,
        forgetPass,
        newPass,
        login,
        verify,
        optionsOther,
        changedPass,
        changedPassProfile,
        verifyCode,
        cahngeUserImge,
        verifyCodeForgetPass,
        verifyCodeForgetPasswithEmail,
        closeChangedPass,
        openChangedPass,
        closeOptionsOther,
        openOptionsOther,
        CloseNewPass,
        openNewPass,
        CloseForgetPass,
        openForgetPass,
        submitLogin,
        CloseVerify,
        openVerify,
        SubmitVerify,
        CloseLogin,
        openLogin,
        openRigester,
        CloseRigester,
        closeChangedPassProfile,
        openChangedPassProfile,
        openverifyCode,
        closeverifyCode,
        opencahngeUserImge,
        closecahngeUserImge,
        openVerifyCodeForgetPass,
        closeverifyCodeForgetPass,
        openverifyCodeForgetPasswithEmail,
        closeverifyCodeForgetPasswithEmail,
        LockedAccess,
        openLockedAcsses,
        CloseLockedAccess,
        LockedAccessDepartment,
        CloseLockedAccessDepartment,
        openLockedAccessDepartment,
        open,
        handleOpen,
        handleClose,
        CloseReaultTest,
        openReaultTest,
        ReaultTest,
        FetshData,
        handelSetFetch,
        paypoints,
        openpaypoints,
        Closepaypoints,
        EndSes,
        handleOpenEndSes,
        handleCloseEndSes,
        handleCloseApprove,
        handleOpenApprove,
        approve,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
