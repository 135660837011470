import *as Style from './Styled';
import *as images from '~/images';
const LockedAccessMsgDepartment = () => {
  return (
    <Style.MsgParents>
      <Style.Image src={images.Lock} />
      <Style.TextMassage >
          هذا القسم غير جاهز  بعد 
      </Style.TextMassage>
    </Style.MsgParents>
  )
}

export default LockedAccessMsgDepartment