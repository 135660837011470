import { Fade, FormControl, FormHelperText, InputLabel, MenuItem } from '@mui/material'
import { SelectItem } from './StyledDropdown'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

const DropDown = (
    {
        colorLabel,
        fontweightlabel,
        fontsizelabel,
        label,
        color,
        width,
        height,
        error,
        errorText,
        borederradius,
        border,
        children,
        borderright,
        borderleft,
        bordertop,
        borderbootom,
        coloricon,
        bordercolor,
        placeholder,
        inputProps,
        ...reset
    }
) => {
    return (
        <>
            <InputLabel id="demo-simple-select-label" style={{ color: colorLabel, fontWeight: fontweightlabel, fontSize: fontsizelabel }}>{label}</InputLabel>
            <SelectItem
                bordercolor={bordercolor}
                color={color}
                width={width}
                height={height}
                border={border}
                error={error}
                borderright={borderright}
                borderleft={borderleft}
                bordertop={bordertop}
                borderbootom={borderbootom}
                borederradius={borederradius}
                coloricon={coloricon}
                placeholder={placeholder ? placeholder : 'أدخل من فضلك'}
                IconComponent={(props) => (<ExpandMoreIcon style={{ cursor: "pointer", color: props.coloricon ? props.coloricon : "#0056D2" }} {...inputProps} />)}
                label={label ? label : ''}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                {...reset}
            >
                {children}
            </SelectItem>
            <FormHelperText style={{ color: '#d32f2f' }} >{errorText}</FormHelperText>
        </>)
}

export default DropDown






