import React from 'react'
import LinearProgress from './LinearProgress';
const TestPage = () => {
  return (
    <>
      <LinearProgress />
    </>
  )
}

export default TestPage