import { styled } from "@mui/material";

export const Section = styled('main')(({ theme }) => (props) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: '32px 48px',
    [theme.breakpoints.down('md')]: {
        padding: '24px  24px'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '12px  12px ',
    },
    [theme.breakpoints.down('xs')]: {
        padding: '12px 6px',
    },
}))

export const RightSection = styled('section')(({ theme }) => (props) => ({
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    [theme.breakpoints.down('lg')]: {
        width: '60%'
    },
    [theme.breakpoints.down('md')]: {
        width: '100%'
    }
}))

export const LeftSection = styled('section')(({ theme }) => (props) => ({
    width: '30%',
    background: '#0056D21A',
    padding: '48px',
    paddingBottom: '20px',
    marginRight: '48px',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
        width: '35%'
    },
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
}))
export const Image = styled('img')(({ theme }) => (props) => ({
    width: '100%',
    height: '100%'
}))
export const QuestsComp = styled('div')(({ theme }) => (props) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px'
}))
export const QuestComp = styled('div')(({ theme }) => (props) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
}))

export const QuestText = styled('div')(({ theme }) => (props) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '0px'
}))

export const NameQuest = styled('h6')(({ theme }) => (props) => ({
    fontSize: "14px",
    fontWeight: 400,
    width: '90%',
    padding: '2px',
    margin: '0',
    color: theme.palette.mode === "light" ? theme.palette.text.light : theme.palette.text.dark,
}))

export const QuestDetails = styled('p')(({ theme }) => (props) => ({
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "17px",
    width: '90%',
    padding: '10px',
    margin: '0',
    color: theme.palette.mode === "light" ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary,
}))
export const HederSection = styled('div')(({ theme }) => (props) => ({
    display: 'flex',
    gap: '22px',
    alignItems: 'center'
}))
export const HederText = styled('h4')(({ theme }) => (props) => ({
    fontSize: '24px',
    fontWeight: 800,
    lineHeight: '29px',
    letterSpacing: '0em',
    margin: 0,
    color: theme.palette.mode === 'light' ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary
}))