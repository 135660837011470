import { useEffect } from "react";
import { links } from './dataLinks';
import { useLocation } from "react-router-dom";
import { Title } from "./StyledLinkTitle";
const LinkTitle = () => {
    const { pathname } = useLocation();
    const title = links.filter((link => link.link == pathname)).map((link) => link.name)
    return (
        <Title>
            {
                title
            }
        </Title>
    )
}

export default LinkTitle