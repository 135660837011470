import React from 'react'
import { InputContainer, InputText } from '../../Auth/StyledAuth'
import { InputFail } from '~/Components/Form'

const VerifyCodeProfileForm = () => {
    return (
        <>
            <InputContainer>
                <InputText>
                    أرسلنا لك رمز مكون من 6 أرقام
                </InputText>
                <InputFail width='100%' placeholder=' الرمز' height='40px' />
            </InputContainer>
        </>
    )
}

export default VerifyCodeProfileForm