import { ButtonBase, InputAdornment } from '@mui/material'
import send from './images/Send Icon.svg';
const inputSearchProps = {

    endAdornment: (
        <InputAdornment
            position="end"
        >
            <ButtonBase style={{ background: '#0056D2', height: '32px', width: '40px', padding: '0px', borderRadius: '5px', display: 'flex', alignItems: "center", justifyContent: 'center', courser: 'pointer', cursor: 'pointer' }} >
                <img src={send} />
            </ButtonBase>
        </InputAdornment>
    )
}

export default inputSearchProps