import React from 'react'
import Question from '../../app/features/Questions'

const QuestionPage = () => {
    return (
        <>
            <Question />

        </>
    )
}

export default QuestionPage