import axios from "axios";
import { store } from "../../app/features";

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${store.getState().Auth.userInfo.token}`,
  },
});

export const api = async (options) => {
  const onSuccess = (response) => response;
  const onError = (error) => error;

  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};
