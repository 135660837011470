import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

// import sessionStorage from 'redux-persist/lib/storage/session';

import userInfo from "./Auth/features/reducers";
import CourseSlice from "../features/AboutCourse/redux/Slices/CourseSlice";
import TestSlice from "../features/AboutCourse/redux/Slices/TestSlice";
import PayPointsSlice from "./PayPoints/redux/payPoints";
import EnrollSlice from "../features/AboutCourse/redux/Slices/EnrollSlice";
import EnrollSlicePack from "../features/AboutPackage/redux/EnrollPack";
import courseTestSlice from "./CourseTest/store/courseTestSlice";
import profileSlice from "./profile/store/profileSlice";

// store in 7 dayes
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
export const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["Auth"],
  // stateReconciler: autoMergeLevel2,
  // expire: 7 * 24 * 60 * 60 * 1000, // 7 days in milliseconds
};


let reducer = combineReducers({
  Auth: userInfo,
  Courses: CourseSlice,
  TestCourse: TestSlice,
  PayPointsSlice,
  EnrollCourse: EnrollSlice,
  EnrollPack: EnrollSlicePack,
  courseTest: courseTestSlice,
  profile: profileSlice,
});

export const rootReducer = persistReducer(persistConfig, reducer);
