import { useState } from "react";
import {
  ContainerProfile,
  Imag,
  ProfileImag,
  TextProfile,
  ToggleMenu,
} from "./StyledMenuToogel";
import { MenuToggl } from "~/images";
import { links } from "../links/dataLinks";
import {
  Button,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import HookNavbar from "~/hooks/hook-navbar";
import AuthDropDown from "../AuthDropDown";
import {
  changeCode,
  changeCodeVerify,
  changeDataProfile,
  changeDivesId,
  changeFcmToken,
  changePhone,
  changeToken,
  changeUser,
} from "../../../../Auth/features/Auth";

import { useDispatch, useSelector } from "react-redux";
import { persistConfig } from "../../../../rootReducer";
const MenuToggle = ({ reg, log }) => {
  const navigate = useNavigate();

  const Logout = () => {
    // console.log("Logout I")
    navigate("/");
    dispatch(changeUser({}));
    dispatch(changeToken(""));
    dispatch(changePhone(""));
    dispatch(changeCode(""));
    dispatch(changeCodeVerify(""));
    dispatch(changeFcmToken(""));
    dispatch(changeDataProfile({}));
    // dispatch(changeDivesId(""))
    // window.location.reload();
  };

  const token = useSelector((state) => state.Auth.userInfo.token);
  const Img = useSelector((state) => state.Auth.userInfo.data.image_path);
  const name = useSelector((state) => state.Auth.userInfo.data.username);

  const dispatch = useDispatch();
  const [openToggleMenu, setOpenToggleMenu] = useState(false);
  const rigesteropen = () => {
    setOpenToggleMenu(false);
  };
  return (
    <>
      <ToggleMenu
        anchor="top"
        open={openToggleMenu}
        onClose={() => setOpenToggleMenu(!openToggleMenu)}
      >
        {token && (
          <ListItem>
            <ListItemButton
              to={"/profile"}
              component={Link}
              onClick={() => setOpenToggleMenu(false)}
            >
              <ListItemText>
                <ContainerProfile>
                  <TextProfile>{name}</TextProfile>
                  <ProfileImag>
                    <Imag src={`${process.env.REACT_APP_BASE_URL}${Img}`} />
                  </ProfileImag>
                </ContainerProfile>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        )}
        {links.map((link, index) => (
          <ListItem key={index}>
            <ListItemButton
              onClick={() => setOpenToggleMenu(false)}
              component={Link}
              to={link.link}
            >
              <ListItemText>{link.name}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
        {token ? (
          <>
          <ListItem key={1122}>
            <ListItemButton
              onClick={() => setOpenToggleMenu(false)}
              component={Link}
              to={"/pay-points"}
            >
              <ListItemText>شراء نقاط</ListItemText>
            </ListItemButton>
          </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => [setOpenToggleMenu(false), Logout()]}
              >
                <ListItemText> الخروج</ListItemText>
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  return [rigesteropen(), log()];
                }}
                component={Link}
              >
                <ListItemText>تسجيل دخول</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  return [rigesteropen(), reg()];
                }}
                component={Button}
              >
                <ListItemText>انضم لنا</ListItemText>
              </ListItemButton>
            </ListItem>
          </>
        )}

        {/* <ListItem >
                    <AuthDropDown userData={dataUser} />
                </ListItem> */}
      </ToggleMenu>
      <IconButton onClick={() => setOpenToggleMenu(true)}>
        <img src={MenuToggl} alt="toggle" />
      </IconButton>
    </>
  );
};

export default MenuToggle;
