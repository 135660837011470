import React from 'react'
import { Li, LinkNav, Links, Ul } from '../../NavbarStyled'
import { links } from './dataLinks'
const LinksMenu = () => {
    return (
        <Ul>
            <Links>
                {
                    links.map((link) => (<Li key={link.id}><LinkNav style={({ isActive }) => ({
                        color: isActive && '#0056D2',
                        fontWeight: isActive && '700'
                    })} to={link.link} >{link.name}</LinkNav></Li>))
                }
            </Links>
        </Ul>
    )
}

export default LinksMenu