import { AppBar, FormControl, IconButton, Toolbar, styled } from "@mui/material";
import { Link, NavLink } from "react-router-dom";

export const Nav = styled(AppBar)(({ theme }) => ({
    width: '100%',
    backgroundColor: 'transparent',
    position: 'static',
    padding: '0px',
    boxShadow: 'none',
    borderBottom: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#b2b2b2b2' : theme.palette.text.dark,
    [theme.breakpoints.down('sm')]: {
        height: '60px'
    }
}))
export const NavTollTip = styled(Toolbar)
    (({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: "10px 48px",
        [theme.breakpoints.down('sm')]: {
            padding: '5px 12px',

        }
    }))
export const AuthSection = styled('span')(({ theme }) => ({
    width: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('lg')]: {
        width: '8%'
    },
    [theme.breakpoints.down('md')]: {
        width: '20%'
    }
}))
export const CenterSection = styled('span')(({ theme }) => ({
    width: '70%',
    display: 'flex',
    alignItems: 'center',
}))
//menu 
export const MenuSection = styled('span')(({ theme }) => ({
    width: '30%%',
}))
export const Ul = styled('ul')(({ theme }) => ({
    listStyle: 'none',
    display: 'inline-block',
    width: '100%'
}))
export const Links = styled('span')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '5px'
}))
export const LinkNav = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    display: 'flex',
    alignItems: "center",
    color: theme.palette.mode === 'light' ? theme.palette.text.light : theme.palette.text.dark,
    '&:hover': {
        color: theme.palette.mode === 'light' ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary,
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&:active': {
        color: theme.palette.mode === 'light' ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary,
        fontWeight: theme.typography.fontWeightMedium,
    }
}))
export const Li = styled('li')(({ theme }) => ({
    margin: '0 5px',
    fontSize: theme.typography.linkNav.fontSize,
    fontWeight: theme.typography.linkNav.fontWeight,
    lineHeight: theme.typography.linkNav.letterSpacing,
    letterSpacing: theme.typography.linkNav.letterSpacing
}))
export const LogoSection = styled('span')(({ theme }) => ({
    padding: '10px 48px 10px 10px',
    height: '48px',
    width: '68px',
    display: 'flex',
    alignItems: 'center'
}))
export const LogoImage = styled('img')(({ theme }) => ({
    alignContent: 'center',
    width: '75px',
    [theme.breakpoints.down('md')]: {
        width: '48px'
    },

}))
export const ActionSection = styled('span')(({ theme }) => ({
    maxHeight: '48px',
}))
export const FormCont = styled(FormControl)(({ theme }) => ({
}))
export const ButtonIcon = styled(IconButton)(({ theme }) => ({
}))

