import { InputContainer, InputText, LinkTo, ButtonTo } from "../StyledAuth";
import { CodeAuthComp, InputFail, MuiButton } from "~/Components/Form";
import { Form, Formik } from "formik";
import { formikProps } from "./data";
import { useEffect, useState, useDeferredValue } from "react";
import { POST_REQUEST } from "../../../../HooksApi/useApi";
import { USeInsertData } from "../../../../HookAxios/postData";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useModalContext } from "../../../../Provider/ModalProvider";
import { useNavigate } from "react-router-dom";
const VerifyCodeForgetPasswithEmailForm = () => {
  const { closeverifyCodeForgetPasswithEmail } = useModalContext();
  const [Status, setStatus] = useState(false);
  const [StutusResend, setStutusResend] = useState(false);
  const [errorrr, setErrorrr] = useState(true);
  const phone = useSelector((state) => state.Auth.userInfo.phone);
  const codeCount = useSelector((state) => state.Auth.userInfo.code);
  const [code, setCode] = useState("");

  const navigate = useNavigate();
  const onSubmit = () => {
    if (!errorrr) {
      setStatus(true);
      VefiyCodeForgetPassWithEmail({
        code,
        phone,
        country_code: codeCount,
      });
    }
  };
  const ReVefiyCodeForget = () => {
    ReVefiyCodeForgetPassWithEmail({
      phone,
      country_code: codeCount,
    });
    setStutusResend(true);
  };
  const onChange = (data) => {
    setCode(data);
  };
  useEffect(() => {
    if (!errorrr) {
      onSubmit();
    }
  }, [errorrr]);

  const dataCode = useDeferredValue(code);
  useEffect(() => {
    if (dataCode !== "") {
      if (dataCode.includes("-")) {
        setErrorrr(true);
      } else {
        setErrorrr(false);
      }
    }
  }, [dataCode]);

  const {
    mutate: VefiyCodeForgetPassWithEmail,
    status: VefiyCodeForgetPassStatusWithEmail,
    data: VefiyCodeForgetPassDataWithEmail,
    error: VefiyCodeForgetPassrrorErorWithEmail,
  } = POST_REQUEST({
    request: (data) => USeInsertData("resend_verification_password", data),
    handelOnSettledError,
    handelOnSettledData,
  });
  function handelOnSettledError(error) {
    setStatus(false);
  }
  function handelOnSettledData(data) {
    setStatus(false);
    closeverifyCodeForgetPasswithEmail();
  }
  const {
    mutate: ReVefiyCodeForgetPassWithEmail,
    status: ReVefiyCodeForgetPassStatusWithEmail,
    data: ReVefiyCodeForgetPassDataWithEmail,
    error: ReVefiyCodeForgetPassrrorErorWithEmail,
  } = POST_REQUEST({
    request: (data) => USeInsertData("resend_email_password", data),
    handelOnSettledError: reVerficatonError,
    handelOnSettledData: reVerficatonData,
  });
  function reVerficatonError(error) {
    setStatus(false);
    setStutusResend(false);
  }
  function reVerficatonData(data) {
    setStatus(false);
    setStutusResend(false);
  }

  return (
    <Formik
      initialValues={formikProps.initialValues}
      validationSchema={formikProps.validationSchema}
      onSubmit={onSubmit}
      render={({ handleChange, handleBlur, values, errors, touched }) => (
        <Form style={{ width: "100%" }}>
          <InputContainer>
            <InputText>ارسلنا لك الرمز</InputText>
            <CodeAuthComp
              onChange={(e) => [handleChange(e), onChange(e)]}
              onBlur={handleBlur}
              onComplete={onSubmit}
              length={6}
              value={values.code}
              error={errorrr}
              errorText={errorrr && touched.code ? "يرجى ادخال الكود" : null}
              autoFocus
              name="code"
            />
            {/* <InputFail
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.code && touched.code}
                            errorText={errors.code && touched.code ? errors.code : null}
                            width='100%' name="code" placeholder='الرمز  ' height='40px' /> */}
          </InputContainer>
          <InputContainer>
            <InputText>
              {StutusResend ? (
                <CircularProgress
                  sx={{ alignSelf: "center", height: "10px" }}
                />
              ) : (
                <ButtonTo onClick={ReVefiyCodeForget}>
                  {" "}
                  <span style={{ color: "Gray", margin: "0 0 0 4px" }}>
                    لم يصلك الرمز؟
                  </span>
                  إعادة إرسال
                </ButtonTo>
              )}
              <ButtonTo
                onClick={() => [
                  closeverifyCodeForgetPasswithEmail(),
                  navigate("/countactUs"),
                ]}
              >
                <span style={{ color: "Gray", margin: "0 0 0 4px" }}>
                  لايمكنك الوصول للبريد الكتروني ؟
                </span>
                تواصل معنا
              </ButtonTo>
            </InputText>
          </InputContainer>
          <InputContainer
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InputText></InputText>

            {Status ? (
              <CircularProgress sx={{ alignSelf: "center" }} />
            ) : (
              <MuiButton text="تحقق" width="60%" height="40px" />
            )}
          </InputContainer>
        </Form>
      )}
    />
  );
};

export default VerifyCodeForgetPasswithEmailForm;
