import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const Foot = styled('footer')(({ theme }) => ({
    marginTop: '48px',
    display: 'flex',
    borderTop: `1px solid `,
    borderColor: theme.palette.mode === 'light' ? ' #BDBDBD' : ' #828282',
    height: '97px',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.lightSecondary : theme.palette.background.darkSecondary,
    [theme.breakpoints.down('md')]: {
        padding: "16px 24px ",
        flexDirection: 'column',
        borderTop: `none `,
        alignItems: 'center',

    },
    [theme.breakpoints.down('sm')]: {
        alignItems: 'inherit '
    },
}))
export const FootTop = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        gap: '10px'
    }
}))
export const FootDow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexBasis: '60%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '  0  80px',
    borderColor: theme.palette.mode === 'light' ? ' #BDBDBD' : ' #828282',
    [theme.breakpoints.down('md')]: {
        borderTop: `1px solid #BDBDBD `,
        gap: '16px',
        padding: '0',
    },
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        alignItems: 'start',
        padding: '0',
        gap: '0px',
        borderTop: `1px solid #BDBDBD `,

    }
}))
export const LinksSection = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
        width: '85%'
    },
    [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'start',
        width: '95%'
    },
}))
export const HeadLinks = styled('h5')(({ theme }) => ({
    fontSize: '24px',
    fontWeight: 700,
    letterSpacing: '0em',
    margin: "0",
    color: theme.palette.mode === 'light' ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary,
    [theme.breakpoints.down('sm')]: {
        fontSize: '20px'
    }

}))
export const Ul = styled('ul')(({ theme }) => ({
    listStyle: 'none',
    width: '100%',
    display: 'inline-block',

}))
export const Links = styled('div')(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    gap: '5px',
    [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
        width: '90%'
    },
}))
export const LinkNav = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    paddingBottom: '5px',
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0em',
    cursor: 'pointer',
    margin: '0 1.5vw',
    color: theme.palette.mode === 'light' ? theme.palette.text.light : theme.palette.text.dark,
    '&:hover': {
        color: theme.palette.mode === 'light' ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary,
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&:active': {
        color: theme.palette.mode === 'light' ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary,
        fontWeight: theme.typography.fontWeightMedium,
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
    },
}))
export const ImgSection = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
}))
export const Img = styled('div')(({ theme }) => ({
    cursor: 'pointer',
}))
export const SectionIcons = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '32px',

}))
export const SectionPerl = styled('div')(({ theme }) => ({
    display: 'flex',
    // flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px'
}))
export const TextTale = styled('div')(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0em',
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
    }
}))
export const TextPerl = styled('p')(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0em',
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
    }
}))
export const Text = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        alignItems: 'start',
        justifyContent: 'center',
        gap: '10px',
    }
}))

