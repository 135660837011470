import { FormControlLabel } from "@mui/material";
import { styled } from "@mui/styles";

export const FromControlLabelCus = styled(FormControlLabel)(({ theme }) => (props) => ({
    "& .MuiTypography-root ": {
        fontWight: 400,
        color: 'red'
    },
}))
export const EmailText = styled('p')(({ theme }) => (props) => ({
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    margin: '0',
    letterSpacing: '0em',
    color: theme.palette.mode === 'light' ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary
}))

