import { Fade, FormHelperText } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import useStyles from "./styles";
import { useAppContext } from "../../../Provider/AppContextProvider";
import ar from "react-phone-input-2/lang/ar.json";
const PhoneNumInput = ({
  width,
  error,
  errorText,
  placeholder,
  size,
  height,
  title,
  disabled,
  border,
  ...reset
}) => {
  const classes = useStyles(disabled);
  const { appContextObj } = useAppContext();
  const removedCountry = ["il"];

  return (
    <div
      dir="ltr"
      //  dir={appContextObj.direction == "rtl" ? "rtl" : "ltr"}
      className={classes.root}
      style={{
        width: !!width ? width : "100%",
        height: !!height ? height : "40px",
        color: disabled ? "rgba(0, 0, 0, 0.38)" : null,
        borderColor: errorText ? "red" : border ? border : "black",
        position: "relative",
        boxSizing: "border-box",
        margin: "0",
        marginBottom: error ? "16px" : 0,
        padding: "0",
      }}
    >
      <PhoneInput
        country={"sy"}
        variant="standard"
        localization={ar}
        // className={classes.phoneControl}
        excludeCountries={removedCountry}
        searchPlaceholder={"ابحث هنا "}
        searchNotFound={" لايوجد نتائج "}
        disabled={disabled}
        {...(size === "small" ? { inputStyle: { height: 40 } } : {})}
        countryCodeEditable={false}
        enableSearch={true}
        disableSearchIcon={true}
        autocompleteSearch={true}
        specialLabel={appContextObj.locale == "" ? "" : ""}
        inputStyle={{
          borderColor: errorText
            ? "#d32f2f"
            : disabled
            ? "rgba(0, 0, 0, 0.3)"
            : null,
          color:
            disabled && appContextObj.theme == "dark"
              ? "rgba(255,255,255,0.5)"
              : appContextObj.theme == "dark"
              ? "#fff"
              : disabled && appContextObj.theme == "light"
              ? "rgba(0,0,0,0.5)"
              : null,
          backgroundColor: "transparent",
        }}
        {...reset}
      />
      {error && <FormHelperText error>{errorText}</FormHelperText>}
    </div>
  );
};

export default PhoneNumInput;
