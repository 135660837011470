import React from 'react'
import { useLottie } from "lottie-react";
import LoaderSubmit from './sucLoader.json';
const SearchLottie = () => {
    const options = {
        animationData: LoaderSubmit,
        loop: true
    };

    const { View } = useLottie(options);

    return <span style={{ width:'80px' }}>{View}</span>;
}

export default SearchLottie