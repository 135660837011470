import { ApiAxios } from "../Configs/Api";
const USeInsertDataWithImage = (url, params, header) => {
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
            ...header
        }
    }
    return ApiAxios.post(url, params, config);
}
const USeAddPayment = (url, payload, header) => {
    const config = {
        headers: {
            ...header
        }
    }
    return ApiAxios.post(url, payload, config);
}
const USeInsertData = (url, params,header) => {
    const config = {
        headers: {
            ...header
        }
    }
    return ApiAxios.post(url, params, config);
}
export { USeInsertData, USeInsertDataWithImage,USeAddPayment };