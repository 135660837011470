import { Box, Drawer, styled } from "@mui/material";

export const ToggleMenu = styled(Drawer)(({ theme }) => (props) => ({
    "& .MuiListItemText-root": {
        color: props.color ? props.color : theme.palette.mode === 'light' ? theme.palette.text.light : theme.palette.text.dark,
    },
    " & .MuiTypography-root": {
        fontSize: theme.typography.linkNav.fontSize,
        fontWeight: theme.typography.linkNav.fontWeight,
    }

}))

export const ContainerProfile = styled(Box)(({ theme }) => (props) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    justifyContent: 'end'
}))

export const ProfileImag = styled(Box)(({ theme }) => (props) => ({
    width: '50px',
    height: '50px',
    borderRadius: '50%'
}))

export const Imag = styled('img')(({ theme }) => (props) => ({
    width: '100%',
    height: '100%',
}))

export const TextProfile = styled('h4')(({ theme }) => (props) => ({
    fontSize: '16px',
    fontWeight: '500',
    color: '#333',
    lineHeight: '0',
    margin: '0'
}))




