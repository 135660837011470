import React from "react";
import { ThreeDots } from "react-loader-spinner";
import { Content, ContentLotile } from "./StyledLoader";
import { logo } from "../../images";
import { motion } from "framer-motion";
const Dots = ({ style }) => {
  return (
    <Content style={style}>
      <motion.img
        src={logo}
        style={{ width: "80px" }}
        alt=""
        initial={{ opacity: 0.5, scale: 0.8 }}
        animate={{ opacity: 1, scale: [0.8, 1, 0.8, 1, 0.8] }}
        transition={{
          duration: 0.2,
          delay: 0.3,
        }}
      />
      <ThreeDots
        height="40"
        width="40"
        radius="9"
        color="#0056D2"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </Content>
  );
};

export default Dots;
