import { useDispatch, useSelector } from "react-redux";
import { useModalContext } from "~/Provider/ModalProvider";
import { useState } from "react";
import { POST_REQUEST } from "~/HooksApi/useApi";
import { USeInsertDataWithImage } from "~/HookAxios/postData";
import { cameraIcon } from "~/images";
import { changeImageUser } from "~/app/features/Auth/features/Auth";
const HookAddImage = () => {
  const dispatch = useDispatch();
  const [Status, setStatus] = useState(false);
  const [Imge, setImge] = useState(cameraIcon);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const token = useSelector((state) => state.Auth.userInfo.token);
  const [erorr, setErorr] = useState("");
  const { closecahngeUserImge } = useModalContext();
  const changeImage = (e) => {
    if (e.target.files[0].size / 1024 >= 5120) {
      setErorr("يجب ان يكون حجم الصورة اقل من 5 ميغا");
    } else if (e.target.files && e.target.files[0]) {
      setErorr("");
      setImge(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  const onSubmit = (values) => {
    if (!selectedFile) setErorr("هذا الحقل مطلوب");
    else {
      setStatus(true);
      const formData = new FormData();
      formData.append("personal_image", selectedFile);
      addImg(formData);
    }
  };
  const {
    mutate: addImg,
    status: ImgStatus,
    data: ImgData,
    error: errorData,
  } = POST_REQUEST({
    request: (data) =>
      USeInsertDataWithImage("add_photo ", data, {
        Authorization: `Bearer ${token} `,
      }),
    handelOnSettledError,
    handelOnSettledData,
  });
  function handelOnSettledError(error) {
    setStatus(false);
  }
  function handelOnSettledData(data) {
    setStatus(false);
    dispatch(changeImageUser(data.data.image_url));
    closecahngeUserImge();
  }

  return {
    onSubmit,
    changeImage,
    Status,
    Imge,
    selectedFile,
    closecahngeUserImge,
    erorr,
    croppedImage,
    setCroppedImage,
    setImageSrc,
    imageSrc,
  };
};

export default HookAddImage;
