import { ButtonBase, styled } from "@mui/material";

export const Button = styled(ButtonBase)(({ theme }) => (props) => ({
    color: props.color ? props.color : theme.palette.mode === 'light' ?
        props.background
            ? theme.palette.text.light : theme.palette.text.lightButton :
        props.background
            ? theme.palette.text.dark : theme.palette.text.darkButton,
    background: props.background ? props.background : `linear-gradient(269.55deg, #0056D2 0%, #0056D2 100%)`,
    borderRadius: props.borderradios ? props.borderRadios : theme.shape.borderRadius,
    fontSize: props.fontSize ? props.fontSize : '14px',
    fontWeight: props.fontWeight ? props.fontWeight : theme.typography.fontWeightMedium,
    lineHeight: props.lineheight ? props.lineheight : '18px',
    letterSpacing: '0em',
    textAlign: props.textalign ? props.textalign : 'right',
    height: props.height ? props.height : '48px',
    maxHeight: props.maxheight ? props.maxheight : '48px',
    width: props.width ? props.width : '105px',
    padding: props.padding ? props.padding : '5px 0',
    margin: props.margin,
    transition: "1s all fade ",
    borderColor: props.bordercolor,

    // '&:hover': {
    //     color: theme.palette.mode === 'light' ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary,
    //     background: "transparent",
    //     border: ' 2px solid',
    //     borderColor: theme.palette.primary.main,
    //     transition: "1s all fade ",

    // },
    '&:active': {
        color: theme.palette.mode === 'light' ?
            theme.palette.text.lightSecondary :
            theme.palette.text.darkSecondary,
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: props.xsFontSize ?
         props.xsFontSize : ''
    }

}))
