// import { isEmpty } from "~/Helpers/isEmpty";

import axios from "axios";

const Seconds = (n) => n * 1000;
const baseURL = `'process.env.REACT_APP_BASE_URL`;

export const getURL = () =>
  `process.env.REACT_APP_BASE_URL`;

const api = {
  baseURL,
  // apiUrl: getURL("api"),
  apiUrl: getURL(),
  timeout: Seconds(100),
  clientToken: "SECRET_TOKEN",
  version: "1.0.0",
  email: "info@taleb.com",
  phone: "+(963) xx-xx-xxx",
  storageUrl: "https://admin.trade-ka.com",
  multipleMediaFileUpload: "admin/file-upload",
  multipleMediaImageUpload: "http://159.69.192.156:9000/api/public/images",
};
export default api

export const ApiAxios = axios.create({ baseURL: process.env.REACT_APP_BASE_URL })

