import styled from "@emotion/styled";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => (props) => ({
    height: props.height ? props.height : '8px',
    borderRadius: props.borderradius ? props.borderradius : theme.shape.bigBorderRadios,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: props.backgroundprogress ? props.backgroundprogress : theme.palette.mode === 'light' ? theme.palette.background.lightSecondary : theme.palette.background.darkSecondary,
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: props.borderradius ? props.borderradius : theme.shape.bigBorderRadios,
        backgroundColor: props.backgroundactive ? props.backgroundactive : theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));