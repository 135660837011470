import { InputContainer, InputText } from '../StyledAuth'
import { MuiButton, PhoneNumInput } from '~/Components/Form'
import { FormattedMessage } from 'react-intl'
import { Form, Formik } from 'formik'
import { formikProps } from './data';
import { CircularProgress } from '@mui/material'

import HookForgetPass from '~/hooks/hookAuth/hook-forgetPass'
const ForgetPassForm = () => {
    const { getPhone, onSubmit, Status, intl } = HookForgetPass()

    return (
        <Formik
            initialValues={formikProps.initialValues}
            validationSchema={formikProps.validationSchema}
            onSubmit={onSubmit}
            render={({ handleChange, handleBlur, values, errors, touched }) => (
                <Form style={{ width: '100%' }}>
                    <InputContainer>
                        <InputText>
                            <FormattedMessage id={`AuthSignUp.phone`} />
                        </InputText>
                        <PhoneNumInput
                            onBlur={handleBlur}
                            inputProps={{ name: "phone" }}
                            onChange={(phoneNumber, country, e) => [handleChange(e), getPhone(Number(phoneNumber.slice(country.dialCode.length)).toString(), country.dialCode)]}
                            error={errors.phone && touched.phone}
                            errorText={errors.phone && touched.phone ? errors.phone : null}
                            name='phone' width='100%' height='40px' placeholder={intl.formatMessage({ id: `AuthSignUp.Placeholderphone` })} />
                    </InputContainer>
                    <InputContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <InputText>
                        </InputText>

                        {
                            Status ? <CircularProgress sx={{ alignSelf: 'center' }} /> : (<MuiButton text="التالي" width='60%' height='40px' />)
                        }

                    </InputContainer>
                </Form >
            )}
        />
    )
}

export default ForgetPassForm