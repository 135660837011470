import { ButtonBase, IconButton, InputAdornment } from "@mui/material";
import { Search } from "~/images";
import ClearIcon from "@mui/icons-material/Clear";

const inputSearchProps = {
  endAdornment: (
    <InputAdornment position="end">
      <ButtonBase
        type="submit"
        style={{
          background: "#0056D2",
          height: "32px",
          width: "40px",
          padding: "0px",
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          courser: "pointer",
          cursor: "pointer",
        }}
      >
        <img src={Search} />
      </ButtonBase>
      {/* <ClearIcon style={{ display: showCancel }} onClick={handleClick} /> */}
    </InputAdornment>
  ),
};

export default inputSearchProps;
