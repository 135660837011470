import { styled } from "@mui/material";

export const Notification = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '10px',
    margin: '32px 16px '
}))
export const TextNotification = styled('h6')(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0em',
    margin: '0'
}))
