import { IconButton, Modal } from '@mui/material'
import { Content, Header, Heading, InputContainer, InputText } from '../StyledAuth'
import { close, Succeed } from '../images';
import { MuiButton } from '~/Components/Form';
import { Notification, TextNotification } from './StyledChangePassword';

const ModalChangePassScc = ({ open, Close, title, buttonText, onclick }) => {
    return (
        <Modal
            open={open}
            onClose={Close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                overflowY: 'auto',
                overflowX: 'hidden'
            }}
        >
            <Content >
                <Header>
                    <Heading >
                        {title}
                    </Heading>
                    <IconButton style={{ position: 'absolute', right: '0' }} onClick={Close}>
                        <img src={close} alt='/' />
                    </IconButton>
                </Header>
                <Notification>
                    <img src={Succeed} alt="" />
                    <TextNotification>
                        تم تغيير كلمة السر بنجاح
                    </TextNotification>
                </Notification>
                <InputContainer>
                    <InputText>
                    </InputText>
                    <MuiButton width='100%' height='40px' text={buttonText} onClick={onclick} />
                </InputContainer>
            </Content>
        </Modal>
    )
}

export default ModalChangePassScc