import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import CustomHelper from "./utils/Helpers/cutom-helpers";
// import {
//   registerMessagingServiceWorker,
//   notificationFun,
// } from "./firebase/firebase";

function iOS() {
  try {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
      navigator.userAgent.match(/FBAN|FBAV/i)
    );
  } catch (error) {
    return false;
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
const isPrivateWindow = window?.privateBrowsingEnabled || window?.isIncognito;
if (CustomHelper.IsNotFaceBookBrowser()) {
  import("./firebase/firebase").then(({ registerMessagingServiceWorker }) => {
    if (typeof window.firebase !== "undefined" && !isPrivateWindow && !iOS()) {
      registerMessagingServiceWorker();
    }
  });
}
root.render(<App />);
