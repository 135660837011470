import { IconButton, useMediaQuery } from "@mui/material";
import {
  Foot,
  FootDow,
  LinkNav,
  SectionIcons,
  SectionPerl,
  TextPerl,
  TextTale,
} from "./StyledFooter";
import { links } from "./data/dataLinks";
import { useTheme } from "@mui/material/styles";
import * as Images from "./data/images";
// import { messaging } from "../../../../firebase/firebase";
import { useModalContext as Modal } from "../../../../Provider/ModalProvider";
import { useDispatch, useSelector } from "react-redux";
import { changeNewDivesId, changeFcmToken } from "../../Auth/features/Auth";
import React, { useEffect } from "react";
import CustomHelper from "../../../../utils/Helpers/cutom-helpers";
const Footer = () => {
  const { handleOpenApprove } = Modal();
  const dispatch = useDispatch();
  const FcmToken = useSelector((state) => state.Auth.userInfo.fcmToken);
  const [tokenFcm, setTokenFcm] = React.useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery("(min-width: 975px)");
  const isPrivateWindow = window?.privateBrowsingEnabled || window?.isIncognito;
  {
    if (CustomHelper.IsNotFaceBookBrowser() && !isMobile && !isPrivateWindow) {
      import("../../../../firebase/firebase").then(({ messaging }) => {
        messaging.onMessage((payload) => {
          if (payload.data.notification_type === "Verification") {
            handleOpenApprove();
            //dispatch(changeNewDivesId(payload.data.device_id));
          }
        });

        (async () => {
          try {
            const token = await messaging.getToken();
            setTokenFcm(token);
          } catch (error) { }
        })();
      });
    }
    React.useEffect(() => {
      if (tokenFcm !== "")
        if (FcmToken === "") dispatch(changeFcmToken(tokenFcm));
    }, [tokenFcm]);
  }

  return (
    <Foot>
      <SectionIcons>
        <LinkNav target="_blank" to="/terms-of-use">
          شروط الاستخدام
        </LinkNav>
        <LinkNav target="_blank" to="/privacy-policy">
          سياسة الخصوصية
        </LinkNav>
      </SectionIcons>
      <FootDow>
        {/* <Text> */}
        <TextTale>
          © {new Date().getFullYear()} منصّة طالب التعليمية. جميع الحقوق محفوظة.
        </TextTale>

        {/* </Text> */}

        <SectionPerl>
          <TextPerl>طُوِّرَ من قبل</TextPerl>
          <a href="https://www.perla-tech.com/" target="_blank">
            <img
              style={{
                width: "13vw",
                minWidth: "70px",
                maxWidth: "158px",
                marginTop: "7px",
              }}
              src={
                theme.palette.mode === "light"
                  ? Images.perlLogoLight
                  : Images.perlLogoDark
              }
              alt="perlLogo"
            />
          </a>
        </SectionPerl>
      </FootDow>
    </Foot>
  );
};

export default Footer;
