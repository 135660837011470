import React from 'react'
import { useLottie } from "lottie-react";
import erorrLoader from './erorrLoader.json';
const ErorrLoader = () => {
    const options = {
        animationData: erorrLoader,
        loop: true
    };

    const { View } = useLottie(options);

    return <span style={{ width: '80px' }}>{View}</span>;
}

export default ErorrLoader