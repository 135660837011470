import { Box, styled } from "@mui/material";
export const SearchedLest = styled(Box)(({ theme }) => ({
    width: '100%',
    maxHight: '30vh',
    height: '20vh',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.light : theme.palette.background.darkSecondary,
    overflow: 'auto',
    position: 'absolute',
    top: '55px',
    padding: '0px 24px',
    boxSizing: 'border-box',
    boxShadow: "0px 5px 25px 0px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.shape.borderRadius,
    zIndex: '2'
}))
export const Heading = styled('h4')(({ theme }) => ({
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '16px',
    color: theme.palette.mode === 'light' ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary,
    [theme.breakpoints.down("xs")]: {
        fontSize: '14px',
    }
}))
export const ItemsSearched = styled('p')(({ theme }) => ({
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: '17px',
    color: theme.palette.mode === 'light' ? theme.palette.text.light : theme.palette.text.dark,
    display: 'flex',
    alignItems: 'center',
}))

