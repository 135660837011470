import { FormattedMessage } from "react-intl";
import * as Yup from "yup";


export const validationSchema = Yup.object().shape({

    personal_image: Yup.array().required(
        <FormattedMessage id={`Auth.passwordValidations`} />
    )
});
export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialValues: {
        personal_image: ''
    },
};

