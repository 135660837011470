import { Button, ButtonBase, styled } from "@mui/material";
export const Content = styled('div')(({ theme }) => (props) => ({
    borderRadius: "20px",
    width: '35vw',
    background: theme.palette.mode === 'light' ? theme.palette.background.light : theme.palette.background.dark,
    padding: '32px 48px',
    position: 'absolute',
    marginTop: props.title === "انضم لنا" && "13%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    direction: 'ltr',
    [theme.breakpoints.down('sm')]: {
        width: '95vw',
        top: '0%',
        left: '0%',
        marginTop: "0",
        position: 'absolute',
        padding: '16px 12px',
        borderRadius: '0'
    }
}))
export const Header = styled('div')(({ theme }) => ({
    width: '100%',
    position: 'relative',
    textAlign: 'center',
    marginBottom: '32px'
}))
export const Heading = styled('h4')(({ theme }) => ({
    fontSize: '24px',
    fontWeight: 800,
    lineHeight: '29px',
    letterSpacing: '0em',
    display: 'inline',
    [theme.breakpoints.down('sm')]: {
        fontSize: '18px'
    },
    color: theme.palette.mode === "light" ? theme.palette.text.light : theme.palette.text.dark
}))
export const Form = styled('form')(({ theme }) => ({
    padding: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
}))
export const InputContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    flexDirection: 'column',
    width: '95%',
    marginBottom: '8px',
}))
export const InputText = styled('h6')(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 400,
    margin: '5px',
    marginBottom: '8px',
    lineHeight: '17px',
    letterSpacing: '0em',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.mode === 'light' ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary,
}))
export const InputTextVer = styled(InputText)(({ theme }) => ({
    [theme.breakpoints.down("xs")]: {
        flexDirection: 'column',
        gap: '10px'
    }
}))


export const InfoForm = styled('span')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
}))
export const LinkTitle = styled('span')(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
}))
export const BarContentForm = styled('div')(({ theme }) => ({
    width: '92%',
    display: 'flex',
    padding: '20px 10px 10px  10px'
}))
export const DivedForm = styled('div')(({ theme }) => ({
    width: '40%',
    padding: '0px',
    borderTop: '1px #E0E0E0 solid',
    marginTop: '10px',
}))
export const IconsForm = styled('div')(({ theme }) => ({
    minWidth: '20%%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
}))
export const Icon = styled('img')(({ theme }) => ({
    height: '24px',
    width: '24px',
    borderRadius: '0px',
    margin: '0  5px'
}))
export const TitleResend = styled('p')(({ theme }) => ({
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: ' rgba(79, 79, 79, 1)',
    display: 'flex',
    width: '80%',
}))
export const Text = styled('div')(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '17px',
    letterSpacing: '0em',
    color: theme.palette.mode === 'light' ? theme.palette.text.light : theme.palette.text.dark,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    }
}))
export const TextLink = styled(ButtonBase)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '17px',
    letterSpacing: '0em',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    }
}))
export const LinkTo = styled(Button)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    letterSpacing: '0em',
    textAlign: 'right',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    }
}))
export const ButtonTo = styled(ButtonBase)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    letterSpacing: '0em',
    textAlign: 'right',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    }
}))
export const ContainImage = styled('div')(({ theme }) => ({
    width: '184px',
    height: '184px',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: 'solid 1px #828282'
}))
export const Image = styled('img')(({ theme }) => ({
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '50%',
}))
