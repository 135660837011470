import { createContext } from "react";
import { createTheme, useTheme } from '@mui/material/styles';
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { useAppContext } from "./AppContextProvider";
import { CacheProvider } from "@emotion/react";

export const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
    const them = useTheme();
    // rtl mui 
    const cacheRtl = createCache({
        key: "muirtl",
        stylisPlugins: [prefixer, rtlPlugin],
    });
    const { appContextObj } = useAppContext();

    const darkTheme = createTheme({
        direction: appContextObj.direction,
        breakpoints: {
            values: {
                xs: 450,
                sm: 768,
                md: 975,
                lg: 1277,
            }
        },
        palette: {
            mode: appContextObj.theme ? appContextObj?.theme : 'light',
            primary: {
                main: '#0056D2',
            },
            secondary: {
                main: '#828282'
            },
            background: {
                light: '#ffffff',
                lightSecondary: '#f9f9f9',
                dark: '#333333',
                darkSecondary: ' #4F4F4F',
            },
            text: {
                light: '#333333',
                lightSecondary: '#000',
                lightButton: '#FFFFFF',
                dark: '#cecece',
                darkSecondary: '#FFFFFF',
                darkButton: '#333333'
            }
        },
        shape: {
            borderRadius: 5,
            bigBorderRadios: 10
        },
        typography: {
            fontFamily: 'Montserrat',
            fontWeightLight: '400',
            fontWeightMedium: '700',
            //fontWeightRegular: '800',
            linkNav: {
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '0em',
            },
            HeadingHero: {
                fontSize: "3vw",
                fontWeight: '800',
                lineHeight: '4vw',
                letterSpacing: '0em',
            }
        }
    });
    return appContextObj.direction === "rtl" ? (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={darkTheme}>
                {children}
            </ThemeProvider>
        </CacheProvider>
    ) : (
        <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>
    );
}
export { ThemeContextProvider }
