import { styled } from "@mui/material";

export const Main = styled('main')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.light : theme.palette.background.dark,
    minHeight: '100vh',
    color: theme.palette.mode === 'light' ? theme.palette.text.light : theme.palette.text.dark,
    boxSizing: 'border-box',
    fontFamily: 'Montserrat',
    transition: '0.5 fade all',
    styleOverrides: {
        body: {
            direction: 'rtl',
            // scrollbarColor: "#6b6b6b #2b2b2b",
            // "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            //     backgroundColor: "#2b2b2b",
            // },
            // "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            //     borderRadius: 8,
            //     backgroundColor: "#6b6b6b",
            //     minHeight: 24,
            //     border: "3px solid #2b2b2b",
            // },
            // "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            //     backgroundColor: "#959595",
            // },
            // "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            //     backgroundColor: "#959595",
            // },
            // "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            //     backgroundColor: "#959595",
            // },
            // "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            //     backgroundColor: "#2b2b2b",
            // },
        },
    },
}))