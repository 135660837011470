import {
  useQuery,
  useMutation,
  useQueryClient,
  useQueries,
} from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useModalContext as Modal } from "../Provider/ModalProvider";

export const GET_REQUEST = ({
  onResault,
  onError,
  queryKey,
  request,
  id,
  enabled,
  intervalRefetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleOpenEndSes } = Modal();
  const navigate = useNavigate();
  const { isLoading, isError, error, isFetching, refetch, data } = useQuery({
    queryKey: [queryKey],
    queryFn: () => request(),
    refetchInterval: intervalRefetch,
    // cacheTime: 50000, //كل اديش بدو يشوف اذا صار تغير           //defualt is 5 minets
    // staleTime: 30000, //مو كل مافوت واطلع غير يغير كل 30 ثانية    //defualt is 0 secound
    // refetchOnMount: false,//is not change data dont get api //defualt true and value {true ,false, 'always'}
    // refetchOnWindowFocus: false,// If focus is on window invoke API again (just) // If not focus is on window dont invoke API never
    // refetchInterval: 2000,// every 2s get api // but, i doesn't focus is on windows so, the query is not work
    //for solution the last problem : =>
    // refetchIntervalInBackground: true
    enabled: enabled, // disabled the api // it's used when click button for get api
    onSuccess: onResault,
    onError: onError,
    // select: (data) => {
    //     const superHeroseName = data.data.map(hero => hero.name);
    //     return superHeroseName;
    // }
  });
  return { data, isLoading, isError, error, isFetching, refetch };
  // function onResault(data) {
  //     if (data?.status === 200)
  //         handleOpenEndSes()
  // }
  function onError(data) {
    if (data?.response.status === 500) navigate("/500");
    else if (data?.response.status === 502) navigate("/502");
    else if (data?.response.status === 503) navigate("/503");
    else if (data?.response.status === 404)
      enqueueSnackbar(data?.response?.data?.message, { variant: "error" });
    else if (data?.response.status === 401) handleOpenEndSes();
  }
};
// export const GET_REQUEST_BY_ID = ({ onResault, onError, queryKey, request, id }) => {
//     const { data, isLoading, isError, error, isFetching, refetch } = useQuery(queryKey, () => request(!!id && id), {
//         onSuccess: onResault,
//         onError: onError,
//     })
//     return { data, isLoading, isError, error, isFetching, refetch, }
// }

export const POST_REQUEST = ({
  queryKey,
  request,
  handelOnSettledError,
  handelOnSettledData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleOpenEndSes } = Modal();
  const handleClickVariant = (data, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(data, { variant });
  };
  const queryClient = useQueryClient();
  return useMutation((data) => request(data), {
    // onSuccess: () => {
    onMutate: async (newHero) => {
      await queryClient.cancelQueries(queryKey);
      if (!!queryKey) {
        const previousHeroData = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, (oldQueryData) => {
          return {
            ...oldQueryData,
            data: [
              { ...newHero },
              !!oldQueryData?.data && { ...oldQueryData?.data },
            ],
            // data: [{ id: oldQueryData?.data?.length + 1, ...newHero }, ...oldQueryData.data]
          };
        });
        return {
          previousHeroData,
        };
      }
    },
    onError: (_error, _hero, context) => {
      queryClient.setQueryData(queryKey, context?.previousHeroData);

      if (_error?.response.status === 401) handleOpenEndSes();
    },
    onSuccess: (data) => {
      // handleClickVariant("تم العملية  بنجاح", 'success')
    },
    onSettled: (data, error) => {
      data && handelOnSettledData(data);
      error && handelOnSettledError(error);
      error && handleClickVariant(`${error?.response.data?.message}`, "error");
    },
  });
};

export const PUT_REQUEST = ({
  queryKey,
  request,
  onSuccessResponse,
  onErrorResponse,
}) => {
  // const { enqueueSnackbar } = useSnackbar();
  // const handleClickVariant = (data, variant) => {
  //     enqueueSnackbar(data, { variant });
  // };

  // const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => request(data),
    onSuccess: (data, variables) => {
      // !data?.ok && onErrorResponse(data)
      // !data?.ok && handleClickVariant(`${data?.originalError?.message}PUT`, 'error')
      // !!data?.ok && handleClickVariant("EDITED", 'success')
      // !!data?.ok && onSuccessResponse(data)
      // queryClient.setQueryData([queryKey, { id: variables.id }], data)
    },
    onSettled: () => {
      if (typeof onSuccessResponse === "function") onSuccessResponse();
    },
    onError: (error) => {
      if (typeof onErrorResponse === "function") onErrorResponse();
    },
  });
};

// export const DELETE_REQUEST = ({ queryKey, request }) => {
//     const { enqueueSnackbar } = useSnackbar();
//     const handleClickVariant = (data, variant) => {
//         enqueueSnackbar(data, { variant });
//     };
//     const queryClient = useQueryClient()
//     return useMutation((data) => request(data), {
//         onMutate: async (newHero) => {
//             const previousValue = queryClient.getQueryData(queryKey);
//             const updatedValue = [!!previousValue?.data && { ...previousValue?.data }];
//             const removeDeleted = updatedValue.filter(
//                 eachValue => eachValue?.id !== newHero?.id
//             );

//             queryClient.setQueryData(queryKey, removeDeleted);

//             return () => queryClient.setQueryData(queryKey, previousValue);
//         },
//         onError: (error, editedValue, rollback) => {
//             rollback();
//             handleClickVariant(error.message, 'error')
//         },

//         onSettled: (data, error, editedValue) => {
//             !!data.ok && handleClickVariant("Deleted", 'success')
//             !data.ok && handleClickVariant(`${data?.originalError?.message}DELETE`, 'error')
//
//             queryClient.removeQueries([queryKey, editedValue.id]);
//             queryClient.refetchQueries(queryKey);
//         }
//     })
// }

export const DELETE_REQUEST = ({
  queryKey,
  request,
  handelOnSettledError,
  handelOnSettledData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleOpenEndSes } = Modal(); // assuming Modal is a custom hook or component
  const handleClickVariant = (data, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(data, { variant });
  };
  const queryClient = useQueryClient();

  return useMutation((data) => request(data), {
    onMutate: async (deletedHero) => {
      await queryClient.cancelQueries(queryKey);
      if (!!queryKey) {
        const previousHeroData = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, (oldQueryData) => {
          return {
            ...oldQueryData,
            data: oldQueryData?.data?.filter(
              (eachHero) => eachHero?.id !== deletedHero?.id
            ),
          };
        });
        return {
          previousHeroData,
        };
      }
    },
    onError: (error, deletedHero, context) => {
      queryClient.setQueryData(queryKey, context?.previousHeroData);

      if (error?.response.status === "401") handleOpenEndSes();
    },
    onSuccess: (data) => {
      // handleClickVariant("تم العملية بنجاح", 'success')
    },
    onSettled: (data, error) => {
      data && handelOnSettledData(data);
      error && handelOnSettledError(error);
      error && handleClickVariant(error?.response.data?.message, "error");
    },
  });
};
