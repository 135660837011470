import React from 'react'
import { MuiButton, RadioButton } from '~/Components/Form'
import { InputContainer, InputText } from '../StyledAuth'
import { formikProps } from './data'
import { CircularProgress, RadioGroup } from '@mui/material'
import { Form, Formik } from 'formik';
import HookOptionOther from '../../../../hooks/hookAuth/hook-OptionOther';
const OptionsOtherForm = () => {
  const { onSubmit, notEmail, setNotEmail, Status, StatusEmaile, openVerifyCodeForgetPass, getEmailData } = HookOptionOther()
  return (
    <Formik
      initialValues={formikProps.initialValues}
      onSubmit={onSubmit}
      render={({ handleChange, handleBlur, values, errors, touched }) => (
        <Form style={{ width: '100%' }}>
          <InputContainer>
            <RadioGroup
              aria-required
              aria-labelledby="demo-radio-buttons-group-label"
              name="email"
              defaultChecked='phone'
              defaultValue="phone"
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ width: '100%' }}
            >
              {

                Status ? <CircularProgress sx={{ alignSelf: 'center' }} />
                  : getEmailData?.data.email ? <RadioButton checked key={getEmailData.data.email} value={"phone"} label=' البريد الإلكتروني' email={getEmailData.data.email} /> : null
              }
              <RadioButton value='contactUs' label='تواصل معنا' />
            </RadioGroup>
          </InputContainer>
          <InputContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <InputText>
            </InputText>
            {getEmailData ? null : setNotEmail(true)}
            {
              StatusEmaile ? <CircularProgress sx={{ alignSelf: 'center' }} /> : <MuiButton text="التالي" width='60%' height='40px' />
            }

          </InputContainer>
        </Form>
      )}
    />)
}

export default OptionsOtherForm