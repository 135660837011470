import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const DropAuth = styled('div')(({ theme }) => (props) => ({
    maxWidth: '186px',
    width: '186px',
    [theme.breakpoints.down("md")]: {
        maxWidth: '100%',
        width: '100%'
    }
}))
export const DropComp = styled('div')(({ theme }) => (props) => ({
    width: '95%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
}))
export const UserImage = styled('div')(({ theme }) => (props) => ({
    width: '48px',
    height: '48px',
}))
export const Image = styled('img')(({ theme }) => (props) => ({
    width: '100%',
    height: '100%',
    borderRadius: '50%'
}))
export const UserText = styled('div')(({ theme }) => (props) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    flex: '1',
    flexDirection: 'column'
}))
export const UserName = styled('h5')(({ theme }) => (props) => ({
    margin: '0',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '17px',
    letterSpacing: '0em',
    color: theme.palette.mode === 'light' ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary
}))
export const UserPoints = styled('h6')(({ theme }) => (props) => ({
    margin: '0',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0em',
    color: theme.palette.mode === 'light' ? theme.palette.text.light : theme.palette.text.dark
}))
export const LinkPage = styled(Link)(({ theme }) => (props) => ({
    margin: '0',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    letterSpacing: '0em',
    color: theme.palette.mode === 'light' ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    gap: '16px',
    textDecoration: 'none',
    width: '100%',
    
}))


