import React from "react";
import { useLottie } from "lottie-react";
import TestResultGreen from "./newScene-green.json";
import TestResultOrange from "./newScene-orange.json";
const TestResultLoader = ({ marks }) => {
  const options = {
    animationData: marks > 8 ? TestResultGreen : TestResultOrange,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <center>
      <div
        style={{
          width: "150px",
          height: "150px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {View}
      </div>
    </center>
  );
};

export default TestResultLoader;
