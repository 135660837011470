import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import UseTranslate from '../../../../hooks/hookTranslate'
// const { translate } = UseTranslate()
const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const validationSchema = Yup.object().shape({

    phone: Yup.string().required(
        <FormattedMessage id={`Auth.phoneValidations`} />
    )
    // .matches(phoneRegExp,
    //     " الرقم غير صالح "
    //     // translate(`Auth.phoneValidationsTwo`)
    // ),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialValues: {
        phone: '',
    },
};