import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { GET_REQUEST } from '../../HooksApi/useApi';
import UseGetData from '../../HookAxios/getdata';
import { useTransition } from "react";
import { QueryClient } from '@tanstack/react-query';

const HookNavbar = () => {
  const [ispannding, startTransition] = useTransition()

  const { search } = useParams()
  const [showLest, setShowLest] = useState('none');
  const [show, setShow] = useState('none');
  const [dataSearch, setDataSearch] = useState(search || "")
  const theme = useTheme();
  const navigate = useNavigate();
  // useEffect(() => {
  //   startTransition(() => {
  //     if (dataSearch)
  //       refetchsearchCourse()
  //     // QueryClient.invalidateQueries({ queryKey: ['searchCourse'] })
  //   })
  // }, [search])
  const dataForm = (e) => {
    e.preventDefault()
    if (dataSearch !== "") {
      refetchsearchCourse()
      // QueryClient.invalidateQueries({ queryKey: ['searchCourse'] })\
      document.getElementById("taleb-seach-form").reset();
      setShow('none')
      navigate(`/search/${dataSearch}`)
      setShowLest('none')

    }
  }

  const { isLoading: LoadsearchCourse, data: datasearchCourse, isError: isErrorsearchCourse, isFetching: fetshResult, error: errorsearchCourse, refetch: refetchsearchCourse }
    = GET_REQUEST({ queryKey: "searchCourse", request: () => UseGetData('get_search_courses', { search: dataSearch }), enabled: search ? true : false })
  const meddle = useMediaQuery(theme.breakpoints.down('lg'));
  const [inputShow, setInputShow] = useState(false);
  const ShowChange = (state) => {
    setInputShow(state)
  }
  const onFucsInput = (e) => {
    if (e.target.value === '') {
      setShow('block')
      setShowLest('none')
    } else {
      setShow('none')
      setShowLest('block')
    }
  }
  const onBlurInput = () => {
    setShow('none')
    setShowLest('none')
  }
  const onChangeInput = (e) => {
    setShowLest('block')
    setShow('none')
    setDataSearch(e.target.value)
    if (e.target.value === '') {
      setShow('block')
      setShowLest('none')
    }
  }

  return {
    showLest, show, onFucsInput, onBlurInput, onChangeInput, inputShow, meddle, ShowChange,
    LoadsearchCourse, datasearchCourse, dataForm, search, fetshResult
  }
}

export default HookNavbar