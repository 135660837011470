import { useModalContext } from "~/Provider/ModalProvider";
import * as NavStyle from "./NavbarStyled";
import * as images from "~/images";
import * as Form from "~/Components/Form";
import * as DataNav from "./data";
import HookNavbar from "~/hooks/hook-navbar";
import * as profile from "../../profile/exportProfile";
import * as Msg from "../../Auth/massgeModal";
import * as Auth from "../../Auth";
import React from "react";
import { useSelector } from "react-redux";
import * as comp from "./Components";
const Navbar = () => {
  const dataUser = useSelector((state) => state.Auth.userInfo.data);
  const dataToken = useSelector((state) => state.Auth.userInfo.token);
  const [user, setUser] = React.useState(false);
  React.useEffect(() => {
    if (dataToken !== "") setUser(true);
    else setUser(false);
  }, [dataToken]);

  const {
    showLest,
    show,
    onFucsInput,
    onBlurInput,
    onChangeInput,
    inputShow,
    meddle,
    ShowChange,
    dataForm,
  } = HookNavbar();
  const {
    openRigester,
    rigester,
    CloseRigester,
    login,
    openLogin,
    CloseLogin,
    forgetPass,
    CloseForgetPass,
    openForgetPass,
    verify,
    CloseVerify,
    submitLogin,
    CloseNewPass,
    newPass,
    SubmitVerify,
    changedPass,
    closeChangedPass,
    openChangedPass,
    optionsOther,
    verifyCode,
    closeverifyCode,
    closeOptionsOther,
    openOptionsOther,
    changedPassProfile,
    cahngeUserImge,
    closecahngeUserImge,
    closeChangedPassProfile,
    verifyCodeForgetPass,
    closeverifyCodeForgetPass,
    verifyCodeForgetPasswithEmail,
    closeverifyCodeForgetPasswithEmail,
    LockedAccess,
    CloseLockedAccess,
    LockedAccessDepartment,
    CloseLockedAccessDepartment,
    CloseReaultTest,
    ReaultTest,
    paypoints,
    Closepaypoints,
    EndSes,
    handleCloseEndSes,
    handleCloseApprove,
    approve,
  } = useModalContext();
  return (
    <NavStyle.Nav>
      <NavStyle.NavTollTip>
        <NavStyle.CenterSection>
          {meddle ? (
            <>
              {!inputShow ? (
                <>
                  <NavStyle.LogoSection>
                    <NavStyle.LinkNav to="/">
                      <NavStyle.LogoImage src={images.logo} />
                    </NavStyle.LinkNav>
                  </NavStyle.LogoSection>
                  <DataNav.LinkTitle />
                </>
              ) : (
                <NavStyle.ButtonIcon onClick={() => ShowChange(false)}>
                  <img src={images.ArrowRight} alt="RightArrow" />
                </NavStyle.ButtonIcon>
              )}
            </>
          ) : (
            <>
              <NavStyle.LogoSection>
                <NavStyle.LinkNav to="/">
                  <NavStyle.LogoImage src={images.logo} />
                </NavStyle.LinkNav>
              </NavStyle.LogoSection>
              <NavStyle.ActionSection>
                {/* <NavStyle.FormCont >
                      <Form.DropDown bordercolor='#0056D2' width='9.1vw' label="استكشف" colorLabel='#0056D2' fontweightlabel='500' borederradius='5px 0px 0px 5px' borderright='none' >
                      </Form.DropDown>
                    </NavStyle.FormCont> */}
                <form
                  id="taleb-seach-form"
                  onSubmit={dataForm}
                  style={{
                    position: "relative",
                    width: "25.3vw",
                    display: "inline-block",
                  }}
                >
                  <Form.InputFail
                    // {...register("search")}
                    focus={onFucsInput}
                    blur={onBlurInput}
                    InputChange={onChangeInput}
                    width="24vw"
                    placeholder="ماذا تريد أن تتعلم؟"
                    inputProps={DataNav.inputSearchProps}
                    borderradius="5px"
                  />
                  {/* <DataNav.SearchedLast display={show} title="تم البحث عنه مؤخرا" data={DataNav.dataSearch.lastSearched} /> */}
                  {/* <DataNav.SearchedLast display={showLest} title="بحوث رائجة" data={DataNav.dataSearch.SearchedLest} /> */}
                </form>
              </NavStyle.ActionSection>
              <NavStyle.MenuSection>
                <DataNav.LinksMenu />
              </NavStyle.MenuSection>
            </>
          )}
        </NavStyle.CenterSection>
        <span
          style={{
            position: "relative",
            width: "50vw",
            margin: " 0 20px",
            display: inputShow ? "block" : "none",
          }}
        >
          <form
            id="taleb-seach-form"
            onSubmit={dataForm}
            style={{
              position: "relative",
              width: "100%",
              display: "inline-block",
            }}
          >
            <Form.InputFail
              focus={onFucsInput}
              blur={onBlurInput}
              InputChange={onChangeInput}
              width="50vw"
              placeholder="ماذا تريد أن تتعلم؟"
              border="none"
            />
            {/* <DataNav.SearchedLast display={show} title="تم البحث عنه مؤخرا" data={DataNav.dataSearch.lastSearched} /> */}
          </form>
          {/* <DataNav.SearchedLast display={showLest} title="بحوث رائجة" data={DataNav.dataSearch.SearchedLest} /> */}
        </span>
        <NavStyle.AuthSection>
          {meddle ? (
            <>
              {" "}
              <NavStyle.ButtonIcon onClick={() => ShowChange(true)}>
                {" "}
                <img src={images.SearchBlue} alt="search" />
              </NavStyle.ButtonIcon>{" "}
              <DataNav.MenuToggle log={openLogin} reg={openRigester} />{" "}
            </>
          ) : (
            <>
              {user ? (
                <>
                  <DataNav.AuthDropDown userData={dataUser} />
                  {/* <comp.Switcher /> */}
                </>
              ) : (
                <>
                  <Form.MuiButton
                    background="transparent"
                    text="تسجيل دخول"
                    onClick={openLogin}
                  />
                  <Form.MuiButton text=" إنشاء حساب" onClick={openRigester} />
                  {/* <comp.Switcher /> */}
                </>
              )}
            </>
          )}
          <Auth.BasicModal
            open={approve}
            Close={handleCloseApprove}
            title="تحذير!"
          >
            <Msg.AprroveConfirmtion />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={EndSes}
            Close={handleCloseEndSes}
            title=".انتهت صلاحية الجلسة، يرجى تسجيل الدخول مجدداً"
          >
            <Msg.EndSition />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={paypoints}
            Close={Closepaypoints}
            title="تاكيد عملية الشراء"
          >
            <Msg.PayPointsConfirm />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={ReaultTest}
            Close={CloseReaultTest}
            title=" لقد تم الانتهاء من تقديم الاختبار"
          >
            <Msg.ResultTest />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={LockedAccessDepartment}
            Close={CloseLockedAccessDepartment}
            title="لايمكن الوصول الى القسم"
          >
            <Msg.LockedAccessMsgDepartment />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={LockedAccess}
            Close={CloseLockedAccess}
            title="هذا الدرس مقفول"
          >
            <Msg.LockedAccessMsg />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={verifyCodeForgetPasswithEmail}
            Close={closeverifyCodeForgetPasswithEmail}
            title=" أدخل الرمز"
            text="الرجوع الى"
            textlink="تسجيل دخول"
            linkto={openLogin}
          >
            <Auth.VerifyCodeForgetPasswithEmailForm />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={verifyCodeForgetPass}
            Close={closeverifyCodeForgetPass}
            title=" أدخل الرمز"
            text="الرجوع الى"
            textlink="تسجيل دخول"
            linkto={openLogin}
          >
            <Auth.VerifyCodeForgetPassForm />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={rigester}
            Close={CloseRigester}
            title=" إنشاء حساب"
            text="لديك حساب على طالب؟"
            textlink="تسجيل دخول"
            linkto={openLogin}
            buttonText=" انضم لنا الان "
          >
            <Auth.RegisterForm />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={cahngeUserImge}
            Close={closecahngeUserImge}
            title="ضع صورة شخصية   "
            buttonText="  حفظ  "
          >
            <Auth.ChangeImageUserForm />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={login}
            Close={CloseLogin}
            title=" تسجيل دخول"
            text="جديد على طالب؟ "
            textlink=" انضم لنا"
            linkto={openRigester}
            textopento="نسيت كلمة السر؟"
            opento={openForgetPass}
            buttonText="تسجيل لدخول"
            onSubmit={submitLogin}
          >
            <Auth.LoginForm />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={forgetPass}
            Close={CloseForgetPass}
            title=" نسيت كلمة السر؟"
            text="الرجوع الى    "
            textlink="  تسجيل دخول "
            buttonText=" التالي "
            linkto={openLogin}
          >
            <Auth.ForgetPassForm />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={verify}
            Close={CloseVerify}
            title="أدخل الرمز"
            buttonText=" التالي "
            linkto={openLogin}
            textopento="أعد إرسال الرمز"
            textopentow="لا يمكنك الوصول إلى هذا الرقم؟"
            opentotwo={openOptionsOther}
          >
            <profile.VerifyCodeProfileForm />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={newPass}
            Close={CloseNewPass}
            title="كلمة السر الجديدة"
            text="الرجوع الى"
            textlink="  تسجيل دخول "
            buttonText=" التالي "
            linkto={openLogin}
            onSubmit={openChangedPass}
          >
            <Auth.NewPassForm />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={optionsOther}
            Close={closeOptionsOther}
            title="خيارات أخرى"
            text="الرجوع الى"
            textlink="  تسجيل دخول "
            buttonText=" التالي "
            linkto={openLogin}
            onSubmit={closeOptionsOther}
          >
            <Auth.OptionsOtherForm />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={verifyCode}
            Close={closeverifyCode}
            title="  تأكيد رقم الهاتف"
          >
            <Auth.VerifyCodeForm />
          </Auth.BasicModal>
          <Auth.BasicModal
            open={changedPassProfile}
            Close={closeChangedPassProfile}
            title=" تغيير كلمة السر"
            text=""
            textlink="  "
            textopento=""
            textopentow="نسيت كلمة السر؟"
            opentotwo={openForgetPass}
          >
            <profile.ChangePassProfileForm />
          </Auth.BasicModal>
          <Auth.ModalChangePassScc
            open={changedPass}
            Close={closeChangedPass}
            title="كلمة السر تغيرت"
            buttonText="تسجيل دخول"
            onclick={openLogin}
          />
        </NavStyle.AuthSection>
      </NavStyle.NavTollTip>
    </NavStyle.Nav>
  );
};

export default Navbar;
