import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
export const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("هذا الحقل مطلوب")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*.-]).{8,}$/,
      " يجب أن تتكون كلمة السر من ثمانية أحرف على الاقل وباللغة الانكليزية وأن تتضمن أحرف كبيرة مثل(ABC) وأحرف صغيرة مثل (abc) وأحرف خاصة مثل (!@#$%^*.) وأرقام مثل (123)")
    .min(8, <FormattedMessage id={`Auth.passwordValidationsTwo`} />),

  new_password: Yup.string()
    .required("هذا الحقل مطلوب")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*.-]).{8,}$/,
      " يجب أن تتكون كلمة السر من ثمانية أحرف على الاقل وباللغة الانكليزية وأن تتضمن أحرف كبيرة مثل(ABC) وأحرف صغيرة مثل (abc) وأحرف خاصة مثل (!@#$%^*.) وأرقام مثل (123)")
    .min(8, <FormattedMessage id={`Auth.passwordValidationsTwo`} />),
  confirm_password: Yup.string()
    .required("هذا الحقل مطلوب")
    .oneOf([Yup.ref("new_password"), null], "  غير مطابق مع كلمة السر "),
});
export const formikProps = {
  validationSchema,
  formik: {
    onChangeValidate: false,
    onBlurValidate: false,
  },
  initialValues: {
    new_password: "",
    password: "",
    confirm_password: "",
  },
};
