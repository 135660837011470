import { ApiAxios } from '../Configs/Api';
const UseGetData = (url, params, header) => {
    
    const config = {
        headers: {
            ...header
        }, params
    }
    console.log("the config is ", config, url)
    return ApiAxios.get(url, config);
}
export default UseGetData;