import * as Style from "./Styled";
import * as images from "~/images";
import * as FormComp from "~/Components/Form";
import { useSelector } from "react-redux";
import { useModalContext } from "~/Provider/ModalProvider";
import TestResultLoader from "~/Components/Loader/lotile/TestResaultLoader/testResault";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setShowDetails,
  setFalsAnswerList,
  setTruthAnswerList,
  setResetQuizGroup,
} from "../../../CourseTest/store/courseTestSlice";

const ResultTest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const testRes = useSelector((state) => state.TestCourse.Result);
  //const id = useSelector((state) => state.Courses.SectionsByCourse.data[0].id);

  const { handelSetFetch, CloseReaultTest } = useModalContext();
  return (
    <Style.MsgParents>
      {/* <Style.Image src={images.succses} /> */}
      <TestResultLoader marks={testRes} />
      <Style.ContMassageLink>
        <Style.TextMassage>لقد حصلت على نتيجة {testRes}/10</Style.TextMassage>
        {testRes !== 10 && (
          <Style.TextMassageLinkBlack
            onClick={() => [
              handelSetFetch(),
              CloseReaultTest(),
              dispatch(setShowDetails(true)),
            ]}
          >
            ( عرض التفاصيل)
          </Style.TextMassageLinkBlack>
        )}
      </Style.ContMassageLink>
      <FormComp.MuiButton
        width="90%"
        text="الذهاب لمحتوى الدورة"
        onClick={() => [navigate(-1), CloseReaultTest()]}
      />
      {testRes !== 10 && (
        <Style.TextMassageLink
          onClick={() => [
            CloseReaultTest(),
            dispatch(setFalsAnswerList([])),
            dispatch(setTruthAnswerList([])),
            dispatch(setResetQuizGroup(true)),
          ]}
        >
          اعادة الاختبار
        </Style.TextMassageLink>
      )}
    </Style.MsgParents>
  );
};

export default ResultTest;
