import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  truthAnswerList: [],
  falsAnswerList: [],
  showDetails: false,
  resetQuizGroup: false,
  quizTitle: "",
};

const courseTestSlice = createSlice({
  name: "course-test",
  initialState,
  reducers: {
    setTruthAnswerList: (state, action) => {
      state.truthAnswerList = action.payload;
    },
    setFalsAnswerList: (state, action) => {
      state.falsAnswerList = action.payload;
    },
    setShowDetails: (state, action) => {
      state.showDetails = action.payload;
    },
    setResetQuizGroup: (state, action) => {
      state.resetQuizGroup = action.payload;
    },
    setQuizTitle: (state, action) => {
      state.quizTitle = action.payload;
    },
  },
});

export const {
  setFalsAnswerList,
  setTruthAnswerList,
  setShowDetails,
  setResetQuizGroup,
  setQuizTitle,
} = courseTestSlice.actions;
export default courseTestSlice.reducer;
