import {
  InputContainer,
  InputText,
  ContainImage,
  Image,
  ButtonTo,
} from "../StyledAuth";
import { Form, Formik } from "formik";
import { MuiButton } from "~/Components/Form";
import { formikProps } from "./data";
import { ButtonBase, CircularProgress } from "@mui/material";
import HookAddImage from "../../../../hooks/hookAuth/hook-AddImageUser";
import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg, getRotatedImage } from "./canvasUtils";
import "./style.css";
import { getOrientation } from "get-orientation/browser";

const ChangeImageUserForm = () => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const {
    onSubmit,
    changeImage,
    Status,
    Imge,
    closecahngeUserImge,
    selectedFile,
    erorr,
    croppedImage,
    setCroppedImage,
    setImageSrc,
    imageSrc,
  } = HookAddImage();
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );

      setCroppedImage(croppedImage);
    } catch (e) {
      // console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      try {
        // apply rotation if needed
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
      } catch (e) {
        console.warn("failed to detect the orientation");
      }

      setImageSrc(imageDataUrl);
    }
  };
  const ORIENTATION_TO_ANGLE = {
    3: 180,
    6: 90,
    8: -90,
  };
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  return (
    <Formik
      initialValues={formikProps.initialValues}
      onSubmit={onSubmit}
      render={({ handleChange, handleBlur, values, errors, touched }) => (
        <Form style={{ width: "100%" }}>
          <InputContainer style={{ alignItems: "center" }}>
            <InputText>الصورة الشخصية</InputText>
            <label htmlFor="personal_image" style={{ cursor: "pointer" }}>
              <ContainImage>
                {
                  // !selectedFile ? <Image src={Imge} /> :
                  // <Cropper
                  //     image={Imge}
                  //     crop={crop}
                  //     zoom={zoom}
                  //     aspect={16 / 9}
                  //     onCropChange={setCrop}
                  //     // onCropComplete={onCropComplete}
                  //     // onZoomChange={setZoom}
                  //     // onRotationChange={setRotation}
                  // />
                  <Image
                    style={{ width: "fit-content", height: "" }}
                    src={Imge}
                  />
                }
              </ContainImage>
            </label>
            <input
              type="file"
              accept="image/*"
              id="personal_image"
              onChange={(e) => [changeImage(e), handleChange(e)]}
              onBlur={handleBlur}
              name="personal_image"
              style={{ display: "none" }}
            />

            {erorr == "" ? null : <span style={{ color: "red" }}>{erorr}</span>}
          </InputContainer>
          <InputContainer>
            <InputText>
              <ButtonTo> </ButtonTo>
              {!Status && (
                <ButtonTo onClick={() => closecahngeUserImge()}>تخطي</ButtonTo>
              )}
            </InputText>
          </InputContainer>
          <InputContainer
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InputText></InputText>
            {Status ? (
              <CircularProgress sx={{ alignSelf: "center" }} />
            ) : (
              <MuiButton text="التالي" width="60%" height="40px" />
            )}
          </InputContainer>
        </Form>
      )}
    />
  );
};

export default ChangeImageUserForm;
