import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .react-tel-input .special-label': {
      // backgroundColor: theme.palette.mode === 'light' ? theme.palette.backgroundColor.light : theme.palette.backgroundColor.dark,
      color: (disabled) =>
        disabled && theme.palette.mode === 'dark'
          ? 'rgba(0,0,0,0.3)'
          : !disabled && theme.palette.mode === 'dark'
            ? 'rgba(255,255,255,0.8)'
            : !disabled && theme.palette.mode === 'light'
              ? 'rgba(0, 0, 0, 0.6)'
              : null,
    },
    '& .special-label': {
      backgroundColor: theme.palette.mode === 'dark' ? '#37474f' : 'white',
      color: (disabled) =>
        disabled && theme.palette.mode === 'dark'
          ? 'rgba(0,0,0,0.3)'
          : !disabled && theme.palette.mode === 'dark'
            ? 'rgba(255,255,255,0.8)'
            : !disabled && theme.palette.mode === 'light'
              ? 'rgba(0, 0, 0, 0.6)'
              : null,
    },
    '& .react-tel-input .flag-dropdown': {
      left: '2px',
      borderRight: "1px solid",
      [theme.breakpoints.down('sm')]: {
        // left: '77%'
      },
    },
    '& .react-tel-input .flag-dropdown .arrow': {
      right: '-6px'
    },
    '& .react-tel-input .country-list .search-box ': {
      margin: '0 8px',
      width: '95%'
    },
    '& .react-tel-input .form-control': {
      padding: '10px 40.5px 10px 70.5px ',
      borderColor: theme.palette.mode === "light" ? theme.palette.text.light : theme.palette.text.dark,
      direction: 'ltr'
    },
    '& .react-tel-input .selected-flag': {
      display: "flex",
      justifyContent: "center",
      padding:"0"
    },
    // ...GeneralStyles(theme)['root'],
    minWidth: 200,
    textAlign: 'left',
    padding: theme.spacing(1),
    paddingTop: '0px',
    '& > .react-tel-input': {
      margin: '0px 0px',
    }, '& .search-box ': {
      width: '80%'
    },
    '&  > .react-tel-input input': {
      width: '100%',
      '&:focus': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
      },
      '&:hover': {
        border: theme.palette.type == 'dark' ? '1px solid #fff' : null,
      },
    },
  },
  phoneControl: {
    width: '100%',
    margin: '2.5px 0px',
    // borderColor: 'red'
    // border: '1px solid green'
  },
}));

export default useStyles;
