import { Route, Routes } from "react-router-dom";
import { Navbar, Footer } from "../app/features/utility";
import { Main } from "../Theme/GeneralStyled";
import { useAppContext } from "../Provider/AppContextProvider";
import QuestionPage from "../pages/QuestionPage";
import RequeuedAuth from "./RequerdAuth";
import { Suspense, lazy } from "react";
import Dots from "../Components/Loader/Dots";
import TestPage from "../pages/TestPage";
import IsAuthenticated from "../Provider/IsAuthenticated";

const SystemManagementLearnPage = lazy(() =>
  import("../pages").then((module) => ({
    default: module.SystemManagementLearnPage,
  }))
);
const Home = lazy(() =>
  import("../pages").then((module) => ({ default: module.Home }))
);
const RatingCoursePage = lazy(() =>
  import("../pages").then((module) => ({ default: module.RatingCoursePage }))
);
const VedCoursePage = lazy(() =>
  import("../pages").then((module) => ({ default: module.VedCoursePage }))
);
const CourseDescriptionPage = lazy(() =>
  import("../pages").then((module) => ({
    default: module.CourseDescriptionPage,
  }))
);
const FileDetailsPage = lazy(() =>
  import("../pages").then((module) => ({
    default: module.FileDetailsPage,
  }))
);
const EnrollCoursePage = lazy(() =>
  import("../pages").then((module) => ({ default: module.EnrollCoursePage }))
);
const AboutCoursePage = lazy(() =>
  import("../pages").then((module) => ({ default: module.AboutCoursePage }))
);
const BadGatewayPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.BadGatewayPage }))
);
const TroubleshootingPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.TroubleshootingPage }))
);
const InternalServerPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.InternalServerPage }))
);
const ContactUSPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.ContactUSPage }))
);
const EditProfilePage = lazy(() =>
  import("../pages").then((module) => ({ default: module.EditProfilePage }))
);
const ProfilePage = lazy(() =>
  import("../pages").then((module) => ({ default: module.ProfilePage }))
);
const AboutUsPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.AboutUsPage }))
);
const SearchResultPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.SearchResultPage }))
);
const NotFoundPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.NotFoundPage }))
);
const PrivacyPolicy = lazy(() =>
  import("../pages").then((module) => ({ default: module.PrivacyPolicy }))
);
const CourseTestPage = lazy(() =>
  import("../pages").then((module) => ({ default: module.CourseTestPage }))
);
const TestPagePart = lazy(() =>
  import("../pages").then((module) => ({ default: module.TestPagePart }))
);
const PayPoints = lazy(() =>
  import("../pages").then((module) => ({ default: module.PayPoints }))
);
const Support = lazy(() =>
  import("../pages").then((module) => ({ default: module.Support }))
);
const AboutCourse = lazy(() =>
  import("../pages").then((module) => ({ default: module.AboutCourse }))
);
const AllCourse = lazy(() =>
  import("../pages").then((module) => ({ default: module.AllCourse }))
);
const AboutPackeg = lazy(() =>
  import("../pages").then((module) => ({ default: module.AboutPackeg }))
);
const Terms = lazy(() =>
  import("../pages").then((module) => ({ default: module.Terms }))
);

const Router = () => {
  const { appContextObj } = useAppContext();
  return (
    <Suspense fallback={<Dots />}>
      <Main dir={appContextObj.direction}>
        {/* <SmoothProvider skew={true} /> */}
        <Navbar />
        <Suspense fallback={<Dots />}>
          <Routes>
            <Route element={<IsAuthenticated />}>
              <Route index element={<Home />} />
              {/* page systemManagementLearnPage */}
              <Route
                path="/system-management-learn"
                element={<SystemManagementLearnPage />}
              />
              {/* page SearchResult */}
              <Route path="/search/:search" element={<SearchResultPage />} />
              {/* PAge Abut Us */}
              <Route path="/about-us" element={<AboutUsPage />} />
              {/* Page profile */}
              <Route element={<RequeuedAuth />}>
                <Route path="profile" element={<ProfilePage />} />
                <Route
                  path="enroll-course/:idCourse"
                  element={<EnrollCoursePage />}
                />
                <Route path="pay-points" element={<PayPoints />} />
                <Route path="edit-profile" element={<EditProfilePage />} />
                <Route path="support" element={<Support />} />
              </Route>
              {/* page Contact Us */}
              <Route path="contact-us" element={<ContactUSPage />} />
              {/* page Course */}
              <Route path="about-course/:idCourse" element={<AboutCourse />} />
              {/* page 404 */}
              <Route path="/*" element={<NotFoundPage />} />
              {/* page AboutCoursePage */}
              <Route
                path="/course-description/:id"
                element={<CourseDescriptionPage />}
              />
              {/* page CourseWorkingPaperPage */}
              <Route
                path="/course-working-paper/:courseId/file/:fileId"
                element={<FileDetailsPage />}
              />
              {/* page AboutVed */}
              <Route
                path="/course-description/:id/:idVed"
                element={<VedCoursePage />}
              />
              <Route
                path="/course-description/:id/:sectionId/:idVed"
                element={<VedCoursePage />}
              />
              {/* page QuestionPage */}
              <Route
                path="/course-description/:id/ved/:idVed/questions"
                element={<QuestionPage />}
              />
              "payPoints"
              {/* page Rating */}
              <Route
                path="about-course/:id/rating"
                element={<RatingCoursePage />}
              />
              <Route path="test-unit/:idUnit" element={<TestPagePart />} />
              {/* page 500 */}
              <Route path="/500" element={<InternalServerPage />} />
              <Route path="/503" element={<TroubleshootingPage />} />
              <Route path="/502" element={<BadGatewayPage />} />
              {/* page privacypolicy */}
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms-of-use" element={<Terms />} />
              <Route path="all-course/:ClassName" element={<AllCourse />} />
              <Route path="AboutPackeg/:PackId" element={<AboutPackeg />} />
              <Route path="TestCourse/:idTest" element={<CourseTestPage />} />
              <Route path="test-page" element={<TestPage />} />
            </Route>
          </Routes>
        </Suspense>
        <Footer />
        {/* </SmoothProvider > */}
      </Main>
    </Suspense>
  );
};

export default Router;
