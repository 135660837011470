import { createSlice } from "@reduxjs/toolkit";

const AuthSlice = createSlice({
  name: "Auth",
  initialState: {
    token: "",
    data: {},
    phone: "",
    code: "",
    codeVerify: "",
    profileData: {},
    divesId: window.navigator.userAgent,
    divesNewId: "",
    fcmToken: "",
    backToHome: false,
  },
  reducers: {
    resetAuth: (state) => {
      // state.token=""
      // state.data={},
      // state.phone=""
      // state.code=""
      // state.codeVerify=""
      // state.profileData="",
      // state.divesId=""
      // state.divesNewId=""
      // state.fcmToken=""
    },
    changeToken: (state, action) => {
      state.token = action.payload;
    },
    changeUser: (state, action) => {
      state.data = action.payload;
    },
    changePhone: (state, action) => {
      state.phone = action.payload;
    },
    changeCode: (state, action) => {
      state.code = action.payload;
    },
    changeCodeVerify: (state, action) => {
      state.codeVerify = action.payload;
    },
    changeImageUser: (state, action) => {
      state.data = {
        ...state.data,
        image_path: action.payload,
      };
    },
    changeDataProfile: (state, action) => {
      state.profileData = action.payload;
    },
    changeDataPr: (state, action) => {
      state.profileData = {
        ...state.profileData,
        name: action.payload.name,
        father_name: action.payload.father_name,
        state_name: action.payload.state_name,
        location_name: action.payload.location_name,
      };
      state.data = {
        ...state.data,
        username: action.payload.name,
      };
    },
    changeDivesId: (state, action) => {
      state.divesId = action.payload;
    },
    changeNewDivesId: (state, action) => {
      state.divesNewId = action.payload;
    },
    changeFcmToken: (state, action) => {
      state.fcmToken = action.payload;
    },
    changeBackToHome: (state, action) => {
      state.backToHome = action.payload;
    },
  },
});
export const {
  changeToken,
  changeUser,
  changePhone,
  changeCode,
  changeCodeVerify,
  changeImageUser,
  changeDataProfile,
  changeDivesId,
  changeNewDivesId,
  changeFcmToken,
  changeDataPr,
  changeBackToHome,
  resetAuth,
} = AuthSlice.actions;

export default AuthSlice.reducer;
