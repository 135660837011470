import React from "react";
import { TextFail } from "./StyledInput";
const InputFail = ({
    color,
    type,
    styles,
    disabled,
    customClasses,
    value,
    focused,
    error,
    size,
    errorText,
    InputChange,
    width,
    height,
    name,
    blur,
    focus,
    labelWidth,
    placeholder,
    label,
    border,
    borderradius,
    inputProps,
    multiline,
    minRows,
    ...reset
}) => {
   

    return (
        <TextFail
            borderradius={borderradius}
            border={border}
            height={height}
            color={color}
            multiline={multiline}
            rows={!!minRows ? minRows : 4}
            // label={label ? label : placeholder}
            placeholder={placeholder ? placeholder : ""}
            variant="outlined"
            width={width}
            type={type}
            name={name}
            style={styles}
            size={!!size ? size : "medium"}
            onChange={InputChange}
            error={error}
            defaultValue={type === "date" ? new Date() : null}
            value={value}
            disabled={disabled}
            onBlur={blur}
            onFocus={focus}
            {...(!!focused ? { focused } : {})}
            helperText={!!errorText ? errorText : ""}
            InputProps={inputProps}
            {...reset}
        />
    );
}


export default InputFail;
