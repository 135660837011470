import React from 'react'
import { CodeAuth } from './StyledCode'
import { FormControl, FormHelperText } from '@mui/material'
import './index.css';
const CodeAuthComp = ({
    error,
    errorText,
    ...rest
}) => {
    return (
        <>
            <FormControl sx={{ width: '100%', alignItems: "center" ,mx:"auto"}} className="custom-styles" >
                <CodeAuth
                    placeholder='-'
                    type='text'
                    {...rest}
                />
                {
                    error ?
                        <FormHelperText style={{ color: "red" }} >
                            {errorText}
                        </FormHelperText> : null
                }
            </FormControl>
        </>
    )
}

export default CodeAuthComp