import { Avatar, Box, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const MsgParents = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px',
    width: '100%'
}))
export const Image = styled(Avatar)(({ theme }) => ({
    width: '48px',
    height: '48px'
}))
export const TextMassage = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    fontWeight: '700',
    lineHeight: "20px",
    color: theme.palette.mode === "light"
        ? theme.palette.text.lightSecondary :
        theme.palette.text.darkSecondary
}))
export const TextMassageLinkBlack = styled(Link)(({ theme }) => ({
    fontSize: "12px",
    fontWeight: '800',
    lineHeight: "17.7px",
    display: 'inline',
    color: theme.palette.mode === "light"
        ? theme.palette.text.light :
        theme.palette.text.dark
}))
export const TextMassageLink = styled(Link)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: '700',
    lineHeight: "17.7px",
    textDecoration: 'none',
    color: theme.palette.primary.main,
}))
export const ContMassageLink = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '2px',
    alignContent: 'center',
    justifyContent: 'center'
}))