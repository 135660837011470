import { BrowserRouter } from "react-router-dom";
import { ThemeContextProvider } from "./theme";
import { AppContextProvider } from "./AppContextProvider";
// import { ReactQueryDevtools } from '@tanstack/react-query/devtools'
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ModalContextProvider } from "./ModalProvider";
import { PersistGate } from "redux-persist/integration/react";
import Provider from "react-redux/es/components/Provider";
import { store } from "../app/features";
import { I18nProvider } from "./i18n";
import { SnackbarProvider } from "notistack";
import { persistStore } from "redux-persist";
import { CssBaseline } from "@mui/material";
export const persistor = persistStore(store);
export const ReactProvider = ({ children }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <AppContextProvider>
            <I18nProvider>
              <ModalContextProvider>
                <ThemeContextProvider>
                  <SnackbarProvider maxSnack={3}>
                    <BrowserRouter>{children}</BrowserRouter>
                  </SnackbarProvider>
                </ThemeContextProvider>
              </ModalContextProvider>
            </I18nProvider>
          </AppContextProvider>
          {/* <ReactQueryDevtools initialIsOpen={false} position='bottom-right' /> */}
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};
