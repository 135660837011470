import { createSlice } from '@reduxjs/toolkit';

const CourseSlice = createSlice({
    name: 'TestCourses',
    initialState: {
        Result: 0,
        idTest: null
    },
    reducers: {
        SetResult: (state, action) => {
            state.Result = action.payload;
        },
        SetIdTest: (state, action) => {
            state.idTest = action.payload;
        },
    },
});
export
    const {
        SetResult,
        SetIdTest
    } = CourseSlice.actions;

export default CourseSlice.reducer;
