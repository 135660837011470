import { Form, Formik } from "formik";
import { InputPassword, MuiButton } from "~/Components/Form";
import { InputContainer, InputText } from "../StyledAuth";
import { FormattedMessage, useIntl } from "react-intl";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { formikProps } from "./data";
import { POST_REQUEST } from "~/HooksApi/useApi";
import { USeInsertData } from "~/HookAxios/postData";
import { useDispatch, useSelector } from "react-redux";
import { useModalContext } from "~/Provider/ModalProvider";
import { changePhone, changeCode, changeCodeVerify } from "../features/Auth";
import { useTransition } from "react";
const NewPassForm = () => {
  const [ispannding, startTransition] = useTransition();
  const { CloseNewPass } = useModalContext();
  const dispatch = useDispatch();
  const phone = useSelector((state) => state.Auth.userInfo.phone);
  const codeCount = useSelector((state) => state.Auth.userInfo.code);
  const codeVerify = useSelector((state) => state.Auth.userInfo.codeVerify);
  const [status, setStatus] = useState(false);
  const intl = useIntl();
  const onSubmit = (values) => {
    NewPass({
      ...values,
      phone,
      country_code: codeCount,
      code: codeVerify,
    });
    setStatus(true);
  };
  const {
    mutate: NewPass,
    status: NewPassStatus,
    data: NewPassData,
    error: NewPassLogin,
  } = POST_REQUEST({
    request: (data) => USeInsertData("new_password_confirmation", data),
    handelOnSettledError,
    handelOnSettledData,
  });
  function handelOnSettledError(error) {
    setStatus(false);
  }
  function handelOnSettledData(data) {
    dispatch(changePhone(""));
    dispatch(changeCode(""));
    dispatch(changeCodeVerify(""));
    startTransition(() => {
      setStatus(false);
      CloseNewPass();
    });
  }
  return (
    <>
      <Formik
        initialValues={formikProps.initialValues}
        validationSchema={formikProps.validationSchema}
        onSubmit={onSubmit}
        render={({ handleChange, handleBlur, values, errors, touched }) => (
          <Form style={{ width: "100%" }}>
            <InputContainer>
              <InputText>كلمة السرالجديدة</InputText>
              <InputPassword
                dir="rtl"
                coloricon=" #0056D2"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={errors.password && touched.password}
                errorText={
                  errors.password && touched.password ? errors.password : null
                }
                name="password"
                width="100%"
                height="40px"
                placeholder="كلمة السرالجديدة"
              />
            </InputContainer>
            <InputContainer>
              <InputText>
                <FormattedMessage id={`AuthSignUp.ConfirmPassword`} />
              </InputText>
              <InputPassword
                dir="rtl"
                coloricon=" #0056D2"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={errors.confirm_password && touched.confirm_password}
                errorText={
                  errors.confirm_password && touched.confirm_password
                    ? errors.confirm_password
                    : null
                }
                name="confirm_password"
                width="100%"
                height="40px"
                placeholder={intl.formatMessage({
                  id: `AuthSignUp.Placeholderpasword`,
                })}
              />
            </InputContainer>
            <InputContainer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputText></InputText>

              {status ? (
                <CircularProgress sx={{ alignSelf: "center" }} />
              ) : (
                <MuiButton text="حفظ" width="60%" height="40px" />
              )}
            </InputContainer>
          </Form>
        )}
      />
    </>
  );
};

export default NewPassForm;
