import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useModalContext } from "../../../Provider/ModalProvider";
import { POST_REQUEST } from "../../../HooksApi/useApi";
import { USeInsertData } from "../../../HookAxios/postData";
import {
  changeToken,
  changeUser,
  changeDivesId,
} from "~/app/features/Auth/features/Auth";
import { useTransition } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
const HookLogin = () => {
  const divecId = useSelector((state) => state.Auth.userInfo.divesId);
  const Fcmtoken = useSelector((state) => state.Auth.userInfo.fcmToken);
  const token = useSelector((state) => state.Auth.userInfo.token);
  const navigate = useNavigate();
  const { openForgetPass, CloseLogin } = useModalContext();
  const dispatch = useDispatch();
  const [isPandding, startTransition] = useTransition();
  const intl = useIntl();
  const [Status, setStatus] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMassge, setErrorMassage] = useState("");
  const [error, setError] = useState(false);
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const onSubmit = (values) => {
    setStatus(true);

    login({
      phone,
      country_code: country,
      password: values.password,
      device_id: divecId,
    });
  };
  const {
    mutate: login,
    status: loginStatus,
    data: loginData,
    error: errorLogin,
  } = POST_REQUEST({
    request: (data) => USeInsertData("log_in", data),
    handelOnSettledError,
    handelOnSettledData,
  });
  const { mutate: FcmToken } = POST_REQUEST({
    request: (data) =>
      USeInsertData("fire_base_token", data, {
        Authorization: `Bearer ${token}`,
      }),
  });
  function handelOnSettledError(erro) {
    setError(true);
    setTimeout(() => {
      setErrorMassage(erro.response.data.message);
    }, 500);
    setStatus(false);
    setTimeout(() => {
      setError(false);
    }, 5000);
    dispatch(changeToken(""));
    dispatch(changeUser({}));
  }
  function handelOnSettledData(data) {
    dispatch(changeToken(data.data.data.token));
    dispatch(changeUser(data.data.data.user));
    setStatus(false);
    setSuccess(true);
    setTimeout(() => {
      FcmToken({ fcm_token: Fcmtoken });
    }, 2000);
    setTimeout(() => {
      CloseLogin({ stay: true });
      // navigate(-1)
    }, "3000");
  }

  const getPhone = (data, country) => {
    setPhone(data);
    setCountry(`00${country}`);
  };

  // React.useEffect(() => {
  //   if (divecId === "") {
  //     dispatch(changeDivesId(Math.random().toString(36).substring(2, 15)));
  //   }
  // }, [Status]);

  return {
    getPhone,
    onSubmit,
    openForgetPass,
    intl,
    Status,
    success,
    error,
    errorMassge,
  };
};

export default HookLogin;
