import { Select, styled } from "@mui/material";

export const SelectItem = styled(Select)(({ theme }) => (props) => ({
    background: props.background ? props.background : 'Transparent',
    width: props.width ? props.width : '100%',
    boxSizing: 'border-box',
    color: props.color ? props.color : theme.palette.mode === 'light' ? theme.palette.text.light : theme.palette.text.dark,
    height: props.height ? props.height : '48px',
    borderRadius: props.borederradius ? props.borederradius : theme.shape.borderRadius,
    padding: props.padding ? props.padding : '0 8px',
    margin: '0',
    "& .MuiOutlinedInput-notchedOutline": {
        border: props.border ? props.border : `1px solid ${props.bordercolor ? props.bordercolor : '#828282'} `,
        borderRight: props.borderright,
        borderLeft: props.borderleft,
        borderTop: props.bordertop,
        borderBottom: props.borderbootom,
    }
}))