import { useDispatch } from "react-redux";
import { useModalContext } from "../../../Provider/ModalProvider";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { changeCode, changePhone } from "~/app/features/Auth/features/Auth";
import { POST_REQUEST } from "../../../HooksApi/useApi";
import { USeInsertData } from "../../../HookAxios/postData";
import { useTransition } from "react";

const HookForgetPass = () => {
  const [isPanding, StartTransition] = useTransition();
  const { openVerifyCodeForgetPass, CloseForgetPass } = useModalContext();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [Succedd, setSuccedd] = useState(false);
  const [Status, setStatus] = useState(false);
  const onSubmit = (values) => {
    setStatus(true);
    forgetPass({
      phone,
      country_code: country,
    });
  };
  const getPhone = (dataPhone, country) => {
    setPhone(dataPhone);
    setCountry(`00${country}`);
  };
  useEffect(() => {
    if (Succedd) {
      dispatch(changePhone(phone));
      dispatch(changeCode(country));
    }
  }, [Succedd]);

  const {
    mutate: forgetPass,
    status: forrgetPassStatus,
    data: forgetPassData,
    error: errorforgetPass,
  } = POST_REQUEST({
    request: (data) => USeInsertData("forget_password", data),
    handelOnSettledError,
    handelOnSettledData,
  });
  function handelOnSettledError(error) {
    setStatus(false);
  }
  function handelOnSettledData(data) {
    setSuccedd(true);
    setStatus(false);
    StartTransition(() => {
      CloseForgetPass();
      openVerifyCodeForgetPass();
    });
  }
  return {
    getPhone,
    onSubmit,
    Status,
    intl,
    openVerifyCodeForgetPass,
    CloseForgetPass,
  };
};

export default HookForgetPass;
