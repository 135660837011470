import {
  InputFail,
  DropDown,
  InputPassword,
  MuiButton,
  PhoneNumInput,
} from "~/Components/Form";
import { InputContainer, InputText } from "../StyledAuth";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { Form, Formik } from "formik";
import { formikProps } from "./data";
import UseRegister from "~/hooks/hookAuth/Hook-Rigster";
import {
  MenuItem,
  CircularProgress,
  LinearProgress,
  Skeleton,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../Services/Api/createApi";

const RegisterForm = () => {
  const intl = useIntl();
  const {
    onSubmit,
    Status,
    dataState,
    GetState,
    syria,
    getPhone,
    GetLocation,
    dataLocation,
    changeLocation,
  } = UseRegister();

  const getStudentClassFun = () => {
    return api({
      url: `/student_class`,
    });
  };

  const useGetStudentClassQuery = () => {
    return useQuery({
      queryKey: ["get-student_class"],
      queryFn: getStudentClassFun,
      select: (data) => data.data.data,
    });
  };

  const { data: studentType } = useGetStudentClassQuery();

  return (
    <>
      <Formik
        initialValues={formikProps.initialValues}
        validationSchema={formikProps.validationSchema}
        onSubmit={onSubmit}
        render={({ handleChange, handleBlur, values, errors, touched }) => (
          <Form style={{ width: "100%" }}>
            <InputContainer>
              <InputText>
                <FormattedMessage id={`AuthSignUp.full_name`} />
              </InputText>
              <InputFail
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={errors.full_name && touched.full_name}
                errorText={
                  errors.full_name && touched.full_name
                    ? errors.full_name
                    : null
                }
                name="full_name"
                width="100%"
                height="40px"
                placeholder={intl.formatMessage({
                  id: `AuthSignUp.Placeholderfull_name`,
                })}
              />
            </InputContainer>
            <InputContainer>
              <InputText>
                <FormattedMessage id={`AuthSignUp.father_name`} />
              </InputText>
              <InputFail
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.father_name}
                error={errors.father_name && touched.father_name}
                errorText={
                  errors.father_name && touched.father_name
                    ? errors.father_name
                    : null
                }
                name="father_name"
                width="100%"
                height="40px"
                placeholder={intl.formatMessage({
                  id: `AuthSignUp.Placeholderfather_name`,
                })}
              />
            </InputContainer>
            <InputContainer>
              <InputText>
                <FormattedMessage id={`AuthSignUp.phone`} />
              </InputText>
              <PhoneNumInput
                onBlur={handleBlur}
                inputProps={{ name: "phone" }}
                onChange={(phoneNumber, country, e) => [
                  handleChange(e),
                  getPhone(
                    Number(
                      phoneNumber.slice(country.dialCode.length)
                    ).toString(),
                    country.dialCode
                  ),
                ]}
                error={errors.phone && touched.phone}
                errorText={errors.phone && touched.phone ? errors.phone : null}
                name="phone"
                width="100%"
                height="40px"
                placeholder={intl.formatMessage({
                  id: `AuthSignUp.Placeholderphone`,
                })}
              />
            </InputContainer>

            {syria ? (
              <InputContainer>
                {GetState ? (
                  <>
                    <InputText>
                      <FormattedMessage id={`AuthSignUp.city`} />
                    </InputText>{" "}
                    <Skeleton sx={{ width: "100%", height: "40px" }} />
                  </>
                ) : (
                  <>
                    <InputText>
                      <FormattedMessage id={`AuthSignUp.city`} />
                    </InputText>
                    <DropDown
                      onChange={(e) => [
                        handleChange(e),
                        changeLocation(e.target.value),
                      ]}
                      onBlur={handleBlur}
                      value={values.state}
                      error={errors.country && touched.country}
                      errorText={
                        errors.country && touched.country
                          ? errors.country
                          : null
                      }
                      name="state"
                      width="100%"
                      height="40px"
                      placeholder={intl.formatMessage({
                        id: `AuthSignUp.Placeholdercity`,
                      })}
                    >
                      {!GetState && dataState
                        ? dataState?.data?.State.map((country) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.name}{" "}
                            </MenuItem>
                          ))
                        : null}
                    </DropDown>
                  </>
                )}
              </InputContainer>
            ) : null}
            {syria ? (
              <InputContainer>
                {GetLocation ? (
                  <>
                    <InputText>المدينة </InputText>
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title="اختر المحافظة اولا"
                    >
                      <Skeleton sx={{ width: "100%", height: "40px" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <InputText>المدينة </InputText>
                    <DropDown
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.country && touched.country}
                      errorText={
                        errors.country && touched.country
                          ? errors.country
                          : null
                      }
                      name="location"
                      width="100%"
                      height="40px"
                      placeholder={intl.formatMessage({
                        id: `AuthSignUp.Placeholdercity`,
                      })}
                    >
                      {dataLocation
                        ? dataLocation?.data.Locations.map((country) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.name}{" "}
                            </MenuItem>
                          ))
                        : null}
                    </DropDown>
                  </>
                )}
              </InputContainer>
            ) : null}

            <InputContainer>
              <InputText>صف الطالب</InputText>
              <DropDown
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.student_class && touched.student_class}
                errorText={
                  errors.student_class && touched.student_class
                    ? errors.student_class
                    : null
                }
                name="student_class"
                width="100%"
                height="40px"
                placeholder={intl.formatMessage({
                  id: `AuthSignUp.Placeholdercity`,
                })}
              >
                {studentType
                  ? studentType?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  : null}
              </DropDown>
            </InputContainer>
            <InputContainer>
              <InputText>
                <FormattedMessage id={`AuthSignUp.password`} />
              </InputText>
              <InputPassword
                dir="rtl"
                coloricon=" #0056D2"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={errors.password && touched.password}
                errorText={
                  errors.password && touched.password ? errors.password : null
                }
                name="password"
                width="100%"
                height="40px"
                placeholder={intl.formatMessage({
                  id: `AuthSignUp.Placeholderpasword`,
                })}
              />
            </InputContainer>
            <InputContainer>
              <InputText>
                <FormattedMessage id={`AuthSignUp.ConfirmPassword`} />
              </InputText>
              <InputPassword
                dir="rtl"
                coloricon=" #0056D2"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ConfirmPassword}
                error={errors.ConfirmPassword && touched.ConfirmPassword}
                errorText={
                  errors.ConfirmPassword && touched.ConfirmPassword
                    ? errors.ConfirmPassword
                    : null
                }
                name="ConfirmPassword"
                width="100%"
                height="40px"
                placeholder=" أعد إدخال كلمة السر للتأكيد"
              />
            </InputContainer>
            <InputContainer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputText></InputText>

              {Status ? (
                <CircularProgress sx={{ alignSelf: "center" }} />
              ) : (
                <MuiButton
                  text={intl.formatMessage({ id: `AuthSignUp.TextButton` })}
                  width="60%"
                  height="40px"
                />
              )}
            </InputContainer>
          </Form>
        )}
      />
    </>
  );
};

export default RegisterForm;
