import { useSelector } from "react-redux";
import { USeInsertData } from "../../../HookAxios/postData";
import { POST_REQUEST } from "../../../HooksApi/useApi";
import { useState } from "react";

const HookVerification = () => {
  const token = useSelector((state) => state.Auth.userInfo.token);
  const [StutusResend, setStutusResend] = useState(false);
  const reVerficaton = () => {
    setStutusResend(true);
    ReVefiyCode();
  };
  const {
    mutate: ReVefiyCode,
    status: ReVefiyCodeStatus,
    data: ReVerfyCodeData,
    error: ReVefiyCoderror,
  } = POST_REQUEST({
    request: (data) =>
      USeInsertData(
        "resend_verification_confirm",
        {},
        { Authorization: `Bearer ${token} ` }
      ),
    handelOnSettledError,
    handelOnSettledData,
  });
  function handelOnSettledError(error) {
    setStutusResend(false);
  }
  function handelOnSettledData(data) {
    setStutusResend(false);
  }
  return { reVerficaton, StutusResend };
};

export default HookVerification;
