import React from 'react';
import { Icon, CircularProgress, Tooltip } from '@mui/material';
import { Button } from './StyledButton';
const MuiButton = ({
    title,
    text,
    loading,
    icon,
    customClass,
    name,
    type,
    onClick,
    variant,
    explanation,
    width,
    color,
    background,
    borderradius,
    fontSize,
    fontWeight,
    lineheight,
    textalign,
    height,
    maxheight,
    padding,
    margin,
    disabled,
    ...pops
}) => {
    return (
        <Tooltip title={!explanation ? title : explanation}>
            <Button
                color={color}
                background={background}
                borderradius={borderradius}
                fontSize={fontSize}
                fontWeight={fontWeight}
                lineheight={lineheight}
                textalign={textalign}
                height={height}
                padding={padding}
                margin={margin}
                width={width}
                variant={variant}
                className={customClass}
                name={name}
                maxheight={maxheight}
                // endIcon={
                //     loading ? (
                //         <CircularProgress color="light" size={15} />
                //     ) : (
                //         <Icon> {icon} </Icon>
                //     )
                // }
                type={type}
                disabled={disabled}
                onClick={onClick}
                {...pops}
            >
                {text}
            </Button>
        </Tooltip >
    );
}

MuiButton.defaultProps = {
    variant: 'outlined',
    type: 'submit',
};

export default MuiButton;
