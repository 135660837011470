import { Alert, CircularProgress } from '@mui/material';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { InputPassword, MuiButton, PhoneNumInput } from '~/Components/Form';
import { ButtonTo, InputContainer, InputText } from '../StyledAuth';
import { formikProps } from './data';
import HookLogin from '~/hooks/hookAuth/Hook-Login';
import LoaderSubmit from '../../../../Components/Loader/lotile/loaderSubmit/LoaderSubmit';
import ErrorLoader from '../../../../Components/Loader/lotile/errorLoader/ErrorLoader';
import { Fade } from 'react-reveal';
const LoginForm = () => {
    const { getPhone, onSubmit, openForgetPass, intl, Status, success, error, errorMassge } = HookLogin()
    return (<>
        <Formik
            initialValues={formikProps.initialValues}
            validationSchema={formikProps.validationSchema}
            onSubmit={onSubmit}
            render={({ handleChange, handleBlur, values, errors, touched }) => (
                <Form style={{ width: '100%' }}>
                    <InputContainer>
                        <InputText>
                            <FormattedMessage id={`AuthSignUp.phone`} />
                        </InputText>
                        <PhoneNumInput
                            onBlur={handleBlur}
                            inputProps={{ name: "phone" }}
                            onChange={(phoneNumber, country, e) => [handleChange(e), getPhone(Number(phoneNumber.slice(country.dialCode.length)).toString(), country.dialCode)]}
                            error={errors.phone && touched.phone}
                            errorText={errors.phone && touched.phone ? errors.phone : null}
                            name='phone'
                            width='100%'
                            height='40px'
                            placeholder={intl.formatMessage({ id: `AuthSignUp.Placeholderphone` })}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputText>
                            <FormattedMessage id={`AuthSignUp.password`} />
                        </InputText>
                        <InputPassword
                            dir='rtl'
                            coloricon=' #0056D2'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            error={errors.password && touched.password}
                            errorText={errors.password && touched.password ? errors.password : null}
                            name='password' width='100%' height='40px' placeholder={intl.formatMessage({ id: `AuthSignUp.Placeholderpasword` })} />
                    </InputContainer>
                    <InputContainer>
                        <InputText>
                            <ButtonTo onClick={openForgetPass} >نسيت كلمة السر؟</ButtonTo>
                            <ButtonTo> </ButtonTo>
                        </InputText>
                    </InputContainer>

                    {
                        success ? null : errorMassge === "" ? null : <Fade> <Alert severity="error">{errorMassge} </Alert></Fade>
                    }
                    <InputContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <InputText>
                        </InputText>
                        {
                            success ? <LoaderSubmit style={{ width: '80px' }} /> : error ? <ErrorLoader style={{ width: '80px' }} /> : Status ? <CircularProgress sx={{ alignSelf: 'center' }} />
                                :
                                <MuiButton text="تسجيل دخول" width='60%' height='40px' />
                        }

                    </InputContainer>
                </Form>
            )}
        />
    </>
    )
}

export default LoginForm