import {
  InputContainer,
  InputText,
  ButtonTo,
  InputTextVer,
} from "../StyledAuth";
import { CodeAuthComp, MuiButton } from "~/Components/Form";
import { Form, Formik } from "formik";
import { formikProps } from "./data";
import { useEffect, useState } from "react";
import { POST_REQUEST } from "../../../../HooksApi/useApi";
import { USeInsertData } from "../../../../HookAxios/postData";
import { CircularProgress, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useModalContext } from "../../../../Provider/ModalProvider";
import { useDeferredValue } from "react";
import { useTransition } from "react";
import { changeCodeVerify } from "../features/Auth";

const VerifyCodeForgetPassForm = () => {
  const dispatch = useDispatch();
  const [isPanding, startTransition] = useTransition();
  const { openOptionsOther, closeverifyCodeForgetPass, openNewPass } =
    useModalContext();
  const [Status, setStatus] = useState(false);
  const [StutusResend, setStutusResend] = useState(false);
  const [errorrr, setErrorrr] = useState(true);
  const [time, settime] = useState(true);
  const [code, setCode] = useState("");
  const phone = useSelector((state) => state.Auth.userInfo.phone);
  const codeCount = useSelector((state) => state.Auth.userInfo.code);
  const onSubmit = () => {
    if (!errorrr) {
      setStatus(true);
      VefiyCodeForgetPass({
        code,
        phone,
        country_code: codeCount,
      });
    }
  };
  const onChange = (data) => {
    setCode(data);
  };
  useEffect(() => {
    if (!errorrr) {
      onSubmit();
    }
  }, [errorrr]);

  useEffect(() => {
    settime(false);
    setTimeout(() => {
      settime(true);
    }, 30000);
  }, [StutusResend]);
  const dataCode = useDeferredValue(code);
  useEffect(() => {
    if (dataCode !== "") {
      if (dataCode.includes("-")) {
        setErrorrr(true);
      } else {
        setErrorrr(false);
      }
    }
  }, [dataCode]);

  const ReVefiyCodeForget = () => {
    ReVefiyCodeForgetPass({ phone, country_code: codeCount });
    setStutusResend(true);
  };
  const {
    mutate: VefiyCodeForgetPass,
    status: VefiyCodeForgetPassStatus,
    data: VefiyCodeForgetPassData,
    error: VefiyCodeForgetPassrrorEror,
  } = POST_REQUEST({
    request: (data) => USeInsertData("verification_password", data),
    handelOnSettledError,
    handelOnSettledData,
  });
  function handelOnSettledError(error) {
    setStatus(false);
  }
  function handelOnSettledData(data) {
    setStatus(false);
    dispatch(changeCodeVerify(code));
    startTransition(() => {
      openNewPass();
      closeverifyCodeForgetPass();
    });
  }
  const {
    mutate: ReVefiyCodeForgetPass,
    status: ReVefiyCodeForgetPassStatus,
    data: ReVefiyCodeForgetPassData,
    error: ReVefiyCodeForgetPassrrorEror,
  } = POST_REQUEST({
    request: (data) => USeInsertData("resend_verification_password", data),
    handelOnSettledError: reVerficatonError,
    handelOnSettledData: reVerficatonData,
  });
  function reVerficatonError(error) {
    setStatus(false);
    setStutusResend(false);
  }
  function reVerficatonData(data) {
    setStatus(false);
    setStutusResend(false);
  }
  return (
    <Formik
      initialValues={formikProps.initialValues}
      // validationSchema={formikProps.validationSchema}
      onSubmit={onSubmit}
      render={({ handleChange, handleBlur, values, errors, touched }) => (
        <Form style={{ width: "100%" }}>
          <InputContainer>
            <InputText>ارسلنا لك الرمز</InputText>
            <CodeAuthComp
              onChange={(e) => [handleChange(e), onChange(e)]}
              onBlur={handleBlur}
              onComplete={onSubmit}
              length={6}
              value={values.code}
              error={errorrr}
              errorText={errorrr && touched.code ? "يرجى ادخال الكود" : null}
              autoFocus
              name="code"
            />
          </InputContainer>
          <InputContainer>
            <InputTextVer>
              {!Status ? (
                <>
                  {StutusResend ? (
                    <CircularProgress
                      sx={{ alignSelf: "center", height: "10px" }}
                    />
                  ) : time ? (
                    <ButtonTo onClick={ReVefiyCodeForget}>
                      {" "}
                      <span style={{ color: "Gray", margin: "0 0 0 4px" }}>
                        لم يصلك الرمز؟
                      </span>
                      إعادة إرسال
                    </ButtonTo>
                  ) : (
                    <ButtonTo
                      style={{ color: "#acacac", fontWeight: "300" }}
                      disabled
                      onClick={ReVefiyCodeForget}
                    >
                      {" "}
                      <span style={{ color: "Gray", margin: "0 0 0 4px" }}>
                        لم يصلك الرمز؟
                      </span>
                      إعادة إرسال
                    </ButtonTo>
                  )}
                  <ButtonTo
                    onClick={() => [
                      openOptionsOther(),
                      closeverifyCodeForgetPass(),
                    ]}
                  >
                    لا يمكنك الوصول إلى هذا الرقم؟{" "}
                  </ButtonTo>
                </>
              ) : null}
            </InputTextVer>
          </InputContainer>
          <InputContainer
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InputText></InputText>
            {Status ? (
              <CircularProgress sx={{ alignSelf: "center" }} />
            ) : (
              <MuiButton text="تحقق" width="60%" height="40px" />
            )}
          </InputContainer>
        </Form>
      )}
    />
  );
};
export default VerifyCodeForgetPassForm;
