import { createSlice } from '@reduxjs/toolkit';

const CourseSlice = createSlice({
    name: 'Courses',
    initialState: {
        AboutCourse: {
            data: null,
        },
        SectionsByCourse: {
            data: null,
        },
        SessionsByCourse: {
            data: null
        }
    },
    reducers: {
        GetAboutCourse: (state, action) => {
            state.AboutCourse.data = action.payload;
        },
        GetSectionsByCourse: (state, action) => {
            state.SectionsByCourse.data = action.payload
        },
        GetSessionsByCourse: (state, action) => {
            state.SessionsByCourse.data = action.payload
        },
    },
});
export const {
    GetAboutCourse,
    GetSectionsByCourse,
    GetSessionsByCourse
} = CourseSlice.actions;

export default CourseSlice.reducer;
