import { styled } from "@mui/material";

export const Content = styled('div')(({ theme }) => (props) => ({
    width: '100%',
    height: '65vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
}))
export const ContentLotile = styled('div')(({ theme }) => (props) => ({
    width: '250px',
    [theme.breakpoints.down("sm")]: {
        width: '150px'
    },
}))