import { Box, Typography, styled } from "@mui/material";

export const MsgParents = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px',
    width: '100%'
}))
export const MsgText = styled(Box)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    color: theme.palette.mode === "light" ? theme.palette.text.light : theme.palette.text.dark
}))
export const MsgTextBold = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '24px',
    display: 'inline',
    margin: ' 0 5px ',
    color: theme.palette.mode === "light" ?
        theme.palette.text.lightSecondary
        : theme.palette.text.darkSecondary
}))
export const MsgTextBoldBlue = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '24px',
    display: 'inline',
    margin: ' 0 5px ',
    color: theme.palette.primary.main,
    alignSelf: 'self-start'
}))
export const Image = styled('img')(({ theme }) => (props) => ({
    width: '184px',
    height: '184px',
    [theme.breakpoints.down('sm')]: {
        alignSelf: 'center'
    }
}))
export const CompImage = styled(Box)(({ theme }) => (props) => ({
    width: '144px',
    height: '144px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderColor: theme.palette.mode === "light" ? theme.palette.text.light : theme.palette.text.dark,
    margin: '12px 0',
    borderRadius: "10px"
}))
