import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { POST_REQUEST } from "../../../HooksApi/useApi";
import { USeInsertData } from "../../../HookAxios/postData";
import { useModalContext } from "../../../Provider/ModalProvider";
const HookOptionOther = () => {
  const {
    openverifyCodeForgetPasswithEmail,
    closeOptionsOther,
    openVerifyCodeForgetPass,
  } = useModalContext();
  const [Status, setStatus] = useState(false);
  const [StatusEmaile, setStatusEmaile] = useState(false);
  const [notEmail, setNotEmail] = useState(false);
  const phone = useSelector((state) => state.Auth.userInfo.phone);
  const code = useSelector((state) => state.Auth.userInfo.code);

  const GetEmail = () => {
    setStatus(true);
    getEmail({
      phone,
      country_code: code,
    });
  };
  useEffect(() => {
    GetEmail();
  }, []);

  const {
    mutate: getEmail,
    status: getEmailStatus,
    data: getEmailData,
    error: getEmailError,
  } = POST_REQUEST({
    request: (data) => USeInsertData("get_email", data),
    handelOnSettledError: ErrorEmail,
    handelOnSettledData: DataEmail,
  });
  function ErrorEmail(error) {
    setNotEmail(true);
    setStatus(false);
  }
  function DataEmail(data) {
    setNotEmail(false);
    setStatus(false);
  }
  const onSubmit = (values) => {
    if (values.email === "contactUs") {
    } else {
      setStatusEmaile(true);
      postEmail({
        phone,
        country_code: code,
      });
    }
  };

  const {
    mutate: postEmail,
    status: postEmailStatus,
    data: postEmailData,
    error: errorpostEmail,
  } = POST_REQUEST({
    request: (data) => USeInsertData("send_email_code", data),
    handelOnSettledError,
    handelOnSettledData,
  });
  function handelOnSettledError(error) {
    setStatusEmaile(false);
  }
  function handelOnSettledData(data) {
    setStatusEmaile(false);
    closeOptionsOther();
    openverifyCodeForgetPasswithEmail();
  }

  return {
    onSubmit,
    notEmail,
    setNotEmail,
    Status,
    StatusEmaile,
    openVerifyCodeForgetPass,
    getEmailData,
    closeOptionsOther,
  };
};

export default HookOptionOther;
