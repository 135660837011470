import { ReactProvider } from "./Provider";
import Router from "./Router";
import CustomHelper from "./utils/Helpers/cutom-helpers";

const App = () => {

  if (CustomHelper.IsNotFaceBookBrowser()) {
    import("./firebase/firebase").then(({ notificationFun }) => {
      notificationFun();
    });
  }

  return (
    <ReactProvider>
      <Router />
    </ReactProvider>
  );
};

export default App;
