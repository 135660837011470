import { styled } from "@mui/material";

export const Title = styled('p')(({ theme }) => (props) => ({
    fontSize: '2.7vw',
    fontWeight: 800,
    lineHeight: '20px',
    letterSpacing: '0em',
    [theme.breakpoints.down('sm')]:{
        fontSize:'16px'
    }
}))
