import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import "yup-phone";
import UseTranslate from "../../../../hooks/hookTranslate";
// const { translate } = UseTranslate()
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const validationSchema = Yup.object().shape({
  full_name: Yup.string()
    .trim()
    .required(<FormattedMessage id={`Auth.full_nameValidations`} />)
    .min(3, "الاسم الكامل قصير جدا")
    .matches(
      /^[\u0600-\u06FF\ufe70-\ufefc-aA-zZ \w ]+$/,
      "لايمكن ان يحنوي الاسم على ارقام او رموز"
    ),
  father_name: Yup.string()
    .trim()
    .required(<FormattedMessage id={`Auth.father_nameValidations`} />)
    .matches(
      /^[\u0600-\u06FF\ufe70-\ufefc-aA-zZ \w]+$/,
      "لايمكن ان يحنوي الاسم على ارقام او رموز"
    ),
  // country: Yup.string().required(
  //     <FormattedMessage id={`Auth.countryValidations`} />
  // ),
  phone: Yup.string()
    .test({
      name: "phone",
      exclusive: true,
      message: "رقم الهاتف غير صالح",
      test: (value) => {
        if (value?.startsWith("+963")) {
          const phone = value?.split(" ")?.join("");
          const regex = /^(!?(\+?963))0?9\d{8}$/;
          const found = phone?.match(regex);
          if (found !== null) return true;
          else return false;
        } else return true;
      },
    })
    .required("يرجى ادخال رقم الهاتف  "),

  student_class: Yup.string().trim().required("صف الطالب مطلوب"),
  password: Yup.string()
    .required(<FormattedMessage id={`Auth.passwordValidations`} />)
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*.-]).{8,}$/,
      " يجب أن تتكون كلمة السر من ثمانية أحرف على الاقل وباللغة الانكليزية وأن تتضمن أحرف كبيرة مثل(ABC) وأحرف صغيرة مثل (abc) وأحرف خاصة مثل (!@#$%^*.) وأرقام مثل (123)" // , <FormattedMessage id={`Auth.passwordValidationsThree`} />
    )
    .min(8, <FormattedMessage id={`Auth.passwordValidationsTwo`} />),
  ConfirmPassword: Yup.string()
    .required(<FormattedMessage id={`Auth.passwordValidations`} />)
    .oneOf([Yup.ref("password"), null], "  غير مطابق مع كلمة السر "),
  // fullName: Yup.boolean()
  //   .required(<FormattedMessage id={`Auth.fullName`} />)
  // termsOfService: Yup.boolean()
  //   .required("The terms and conditions must be accepted.")
  //   .oneOf([true], "The terms and conditions must be accepted."),

  // email: Yup.string().when("isEmail", {
  //   is: (val) => val,
  //   then: Yup.string().trim().required("email is a required field").email("please enter a valid email"),
  //   otherwise: Yup.string().notRequired()
  // }),
});

export const formikProps = {
  validationSchema,
  formik: {
    onChangeValidate: false,
    onBlurValidate: false,
  },
  initialValues: {
    full_name: "",
    father_name: "",
    country: "",
    phone: "",
    student_class: "",
    password: "",
    state: "",
    location: "",
    ConfirmPassword: "",
  },
};
