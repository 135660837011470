import { InputContainer, InputText } from "../../Auth/StyledAuth";
import { InputPassword, MuiButton } from "~/Components/Form";
import { Form, Formik } from "formik";
import { formikProps } from "./data";
import { useState } from "react";
import { POST_REQUEST } from "../../../../HooksApi/useApi";
import { USeInsertData } from "../../../../HookAxios/postData";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useModalContext } from "~/Provider/ModalProvider";
const ChangePassProfileForm = () => {
  const { CloseForgetPass } = useModalContext();
  const [status, setStatus] = useState(false);
  const token = useSelector((state) => state.Auth.userInfo.token);
  const onSubmit = (values) => {
    changePass({
      ...values,
    });
    setStatus(true);
  };
  const {
    mutate: changePass,
    status: changePassStatus,
    data: changePassData,
    error: changePassLogin,
  } = POST_REQUEST({
    request: (data) =>
      USeInsertData("change_password", data, {
        Authorization: `Bearer ${token} `,
      }),
    handelOnSettledError,
    handelOnSettledData,
  });
  function handelOnSettledError(error) {
    setStatus(false);
  }
  function handelOnSettledData(data) {
    setStatus(false);
    CloseForgetPass();
  }

  return (
    <>
      <Formik
        initialValues={formikProps.initialValues}
        validationSchema={formikProps.validationSchema}
        onSubmit={onSubmit}
        render={({ handleChange, handleBlur, values, errors, touched }) => (
          <Form style={{ width: "100%" }}>
            <InputContainer>
              <InputText>كلمة السر القديمة</InputText>
              <InputPassword
                coloricon=" #0056D2"
                dir="rtl"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={errors.password && touched.password}
                errorText={
                  errors.password && touched.password ? errors.password : null
                }
                name="password"
                width="100%"
                height="40px"
                placeholder={`أدخل كلمة السر  الحالية `}
              />
            </InputContainer>
            <InputContainer>
              <InputText>كلمة السر</InputText>
              <InputPassword
                coloricon=" #0056D2"
                dir="rtl"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.new_password}
                error={errors.new_password && touched.new_password}
                errorText={
                  errors.new_password && touched.new_password
                    ? errors.new_password
                    : null
                }
                name="new_password"
                width="100%"
                height="40px"
                placeholder={`أدخل كلمة السر  الجديدة `}
              />
            </InputContainer>
            <InputContainer>
              <InputText>تأكيد كلمة السر</InputText>
              <InputPassword
                coloricon=" #0056D2"
                dir="rtl"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirm_password}
                error={errors.confirm_password && touched.confirm_password}
                errorText={
                  errors.confirm_password && touched.confirm_password
                    ? errors.confirm_password
                    : null
                }
                name="confirm_password"
                width="100%"
                height="40px"
                placeholder={`أدخل كلمة السر  الحالية `}
              />
            </InputContainer>
            <InputContainer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputText></InputText>

              {status ? (
                <CircularProgress sx={{ alignSelf: "center" }} />
              ) : (
                <MuiButton text="حفظ" width="60%" height="40px" />
              )}
            </InputContainer>
          </Form>
        )}
      />
    </>
  );
};
export default ChangePassProfileForm;
