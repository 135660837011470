import { FormControl, Radio, useTheme } from "@mui/material";
import { EmailText, FromControlLabelCus } from "./Styled";

const RadioButton = ({ value, label, width, fontweight, minheight, email }) => {
  const theme = useTheme();

  return (
    <>
      <FormControl
        style={{
          width: width ? width : "95%",
          margin: "16PX auto",
          padding: "5px 7px",
          background:
            theme.palette.mode === "light"
              ? theme.palette.background.lightSecondary
              : theme.palette.background.darkSecondary,
          borderRadius: theme.shape.borderRadius,
          fontWeight: "400",
        }}
      >
        <FromControlLabelCus
          value={value}
          control={<Radio />}
          label={label}
          sx={{
            color:
              theme.palette.mode === "light"
                ? theme.palette.text.lightSecondary
                : theme.palette.text.darkSecondary,
            fontWeight: fontweight ? fontweight : 400,
            minHeight: minheight ? minheight : "40px",
          }}
        ></FromControlLabelCus>
        <EmailText>{email}</EmailText>
      </FormControl>
    </>
  );
};

export default RadioButton;
