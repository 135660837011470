import { createSlice } from '@reduxjs/toolkit';

const PayPointsSlice = createSlice({
  name: 'payPoints',
  initialState: {
    points: '',
    payments: '',
  },
  reducers: {
    changePoint: (state, action) => {
      state.points = action.payload;
    },
    changePayments: (state, action) => {
      state.payments = action.payload;
    },
  }
});
export const {
  changePoint,
  changePayments
} = PayPointsSlice.actions;

export default PayPointsSlice.reducer;