import Box from '@mui/material/Box';
import { BorderLinearProgress } from './Styled';

const ProgressLine = (
    {
        width,
        value,
        height,
        borderradios,
        backgroundprogress,
        backgroundactive,


    }
) => {
    return (
        <Box sx={{ width: width ? width : '95%' }}>
            <BorderLinearProgress variant="determinate" value={value}
                backgroundprogress={backgroundprogress}
                backgroundactive={backgroundactive}
                borderradios={borderradios}
                height={height}
            />
        </Box>
    );
}

export default ProgressLine