import *as Style from './Styled';
import *as images from '~/images';
import * as FormComp from '~/Components/Form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useModalContext } from '../../../../../Provider/ModalProvider';
const LockedAccessMsg = () => {
  const { CloseLockedAccess } = useModalContext()
  const navigate = useNavigate()
  const idCourse = useSelector(state => state.Courses.AboutCourse.data.id)
  return (
    <Style.MsgParents>
      <Style.Image src={images.Lock} />
      <Style.TextMassage >
        عليك بالتسجيل في الدورة ليمكنك مشاهدة هذا الدرس!
      </Style.TextMassage>
      <FormComp.MuiButton text="سجل الآن" width='100%' onClick={() => [navigate(`/enroll-course/${idCourse}`), CloseLockedAccess()]} />
    </Style.MsgParents>
  )
}

export default LockedAccessMsg