import { GET_REQUEST, POST_REQUEST } from "../../../HooksApi/useApi";
import UseGetData from "../../../HookAxios/getdata";
import { USeInsertData } from "../../../HookAxios/postData";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeToken, changeUser } from "~/app/features/Auth/features/Auth";
import { useModalContext } from "~/Provider/ModalProvider";
const UseRegister = () => {
  const divecId = useSelector((state) => state.Auth.userInfo.divesId);
  // const divecID = Math.random().toString(36).substring(2, 15);
  const Fcmtoken = useSelector((state) => state.Auth.userInfo.fcmToken);
  const token = useSelector((state) => state.Auth.userInfo.token);

  const dispatch = useDispatch();
  const [Status, setStatus] = useState(false);
  const [syria, setSyria] = useState(false);
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("963");
  const [idLocation, setIdLocation] = useState("");
  const { opencahngeUserImge } = useModalContext();
  const getPhone = (phone, country) => {
    setPhone(phone);
    setCountry(`00${country}`);
  };
  function handelOnSettledError(error) {
    setStatus(false);
    dispatch(changeToken(""));
    dispatch(changeUser({}));
  }
  function handelOnSettledData(data) {
    dispatch(changeToken(data.data.data.token));
    dispatch(changeUser(data.data.data.user));
    setTimeout(() => {
      FcmToken({ fcm_token: Fcmtoken });
    }, 2000);
    setStatus(false);
    opencahngeUserImge();
  }
  const {
    mutate: register,
    status: registerStatus,
    data: registerDataResponse,
    error: registerError,
  } = POST_REQUEST({
    request: (data) => USeInsertData("register", data),
    handelOnSettledError,
    handelOnSettledData,
    // queryKey: 'rigster'
  });
  const { mutate: FcmToken } = POST_REQUEST({
    request: (data) =>
      USeInsertData("fire_base_token", data, {
        Authorization: `Bearer ${token}`,
      }),
  });
  const onSubmit = (values) => {
    setStatus(true);
    register({
      ...values,
      phone,
      country_code: country,
      country: Number(country),
      device_id: divecId,
    });
  };
  // const changeCountry = (id) => {
  //     setId(id)
  // };
  const changeLocation = (id) => {
    setIdLocation(id);
  };
  const {
    isLoading: getCountry,
    data: dataCountry,
    isError: isErrorCountry,
    error: errorCountry,
    refetch: refetchCountry,
  } = GET_REQUEST({
    queryKey: "getCountry",
    request: () => UseGetData("get_country"),
    enabled: true,
  });
  const {
    isLoading: GetState,
    data: dataState,
    isError: isErrorState,
    error: errorState,
    isFetching: FitchingState,
    refetch: refetchState,
  } = GET_REQUEST({
    queryKey: "getState",
    request: () => UseGetData("get_state", { code: Number(country) }),
    enabled: false,
  });
  const {
    isLoading: GetLocation,
    data: dataLocation,
    isError: isErrorLocation,
    error: errorLocation,
    isFetching: FitchingLocation,
    refetch: refetchLocation,
  } = GET_REQUEST({
    queryKey: "getLocation",
    request: () => UseGetData("get_locations", { id: idLocation }),
    enabled: false,
  });
  useEffect(() => {
    if (Number(country) === 963) {
      setSyria(true);
      refetchState();
    } else {
      setSyria(false);
    }
  }, [country]);
  useEffect(() => {
    if (idLocation !== "") {
      refetchLocation();
    }
  }, [idLocation]);
  // useEffect(() => {
  //   if (divecId === "")
  //     dispatch(changeDivesId(Math.random().toString(36).substring(2, 15)));
  // }, []);

  return {
    onSubmit,
    Status,
    dataCountry,
    getCountry,
    dataState,
    GetState,
    syria,
    getPhone,
    GetLocation,
    dataLocation,
    changeLocation,
  };
};

export default UseRegister;
