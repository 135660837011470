import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useModalContext } from '../Provider/ModalProvider';
const RequeuedAuth = () => {
    const { openLogin } = useModalContext()
    const token = useSelector((state) => state.Auth.userInfo.token)
    const location = useLocation()
    return (
        token !== "" ? <Outlet /> : <> {
            openLogin()
            } <Navigate to='/ ' state={{ form: location }} replace /></>
    )
}

export default RequeuedAuth