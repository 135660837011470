import { createContext, useContext, useState } from "react";
const theme = localStorage.getItem("theme");
const AppContextData = {
    theme: theme||'light', // dark , light
    locale: 'ar', // en , ar
    direction: 'rtl', //ltr , rtl
    langs: {
        ar: {
            text: ' العربية ',
            // icon: <Flag  height="10" code="sau" />,
            code: 'ar',
        },
        en: {
            text: 'English',
            //  icon: <Flag height="10" code="gb" />,
            code: 'en',
        },
    },
};

// initializing context
export const AppContext = createContext(AppContextData);

// setting a helper function to use context
export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
    const [appContextObj, setAppContext] = useState(AppContextData);

    const setContext = (data) => {
        setAppContext((currContext) => ({ ...currContext, ...data }));
    }
    return (
        <AppContext.Provider value={{ appContextObj, setContext }}>
            {children}
        </AppContext.Provider>
    );
}