import React from 'react';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';

const RatingComponent = ({
  value,
  name,
  readOnly,
  size = 'small',
  ...rest
}) => {
  return (
    <span style={{ margin: '0 5px' }}>
      <Rating
        precision={0.5}
        name={name}
        size={size}
        value={value}
        readOnly={readOnly}
        emptyIcon={
          <StarIcon
            style={{
              opacity: 1,
              color: 'rgba(151, 151, 144, 0.48)',
              display: 'inline',
            }}
            fontSize="inherit"
          />
        }
        {...rest} />
    </span>
  );
};

export default RatingComponent;
