import { createSlice } from '@reduxjs/toolkit';

const EnrollSlicePack = createSlice({
    name: 'EnrollPack',
    initialState: {
        code: '',
        status: false,
        loading: false,
        discount: 100
    },
    reducers: {
        changeLoading: (state, action) => {
            state.loading = action.payload;
        },
        changeStatus: (state, action) => {
            state.status = action.payload;
        },
        changeDiscount: (state, action) => {
            state.discount = action.payload;
        },
        changeCode: (state, action) => {
            state.code = action.payload;
        },
    }
});
export const {
    changeLoading,
    changeStatus,
    changeDiscount,
    changeCode
} = EnrollSlicePack.actions;

export default EnrollSlicePack.reducer;