import { TextField, styled } from "@mui/material";

export const TextFail = styled(TextField)(({ theme }) => (props) => ({
  background: props.background ? props.background : "Transparent ",
  width: props.width ? props.width : "100%",
  "& .MuiInputBase-root ": {
    borderRadius: props.borderradius
      ? props.borderradius
      : theme.shape.borderRadius,
    height: props.height ? props.height : "48px",
    width: props.width ? props.width : "100%",
    fontWeight: props.fontweight ? props.fontweight : "500",
    color: props.color
      ? props.color
      : theme.palette.mode === "light"
      ? theme.palette.text.light
      : theme.palette.text.dark,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: props.border
      ? props.border
      : `${theme.palette.secondary.main} 1px solid`,
  },
  "& .Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: props.border
        ? props.border
        : `${theme.palette.secondary.main} 1px solid`,
      outline: "none",
    },
  },
  "& .MuiInputBase-input": {
    color: props.color
      ? props.color
      : theme.palette.mode === "light"
      ? theme.palette.text.light
      : theme.palette.text.dark,
    fontSize: props.fontsize ? props.fontsize : "14px",
    fontWeight: props.fontwight ? props.fontwight : "400",
  },
  "& .muirtl-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input:autofill": {
    boxShadow: "none",
  },
  "&  .MuiInputBase-input": {
    direction: props.dir,
  },
}));
