import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import UseTranslate from '../../../../hooks/hookTranslate'
// const { translate } = UseTranslate()

export const validationSchema = Yup.object().shape({
    code: Yup.number().required(
        `هذا الحقل مطلوب`
    ),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialValues: {
        code: '',
    },
};