import React, { useState } from 'react';
import { FormControl, IconButton, Slide } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { TextFail } from '../inputText/StyledInput';

function InputPassword({
  type,
  styles,
  disabled,
  customClasses,
  value,
  focused,
  error,
  size,
  errorText,
  InputChange,
  width,
  name,
  blur,
  labelWidth,
  placeholder,
  label,
  coloricon,
  color,
  height,
  border,
  borderradius,
  inputProps,
  dir,
  ...reset
}) {
  const [showPassword, setShowPassword] = useState(true);

  return (
    <TextFail
      label={label ? label : ''}
      placeholder={placeholder ? placeholder : ' أدخل كلمة السر ...'}
      variant="outlined"
      type={showPassword ? 'text' : 'password'}
      name={name}
      style={styles}
      size={!!size ? size : 'medium'}
      onChange={InputChange}
      error={error}
      value={value}
      disabled={disabled}
      width={width}
      onBlur={blur}
      height={height}
      dir={dir}
      {...(!!focused ? { focused } : {})}
      InputProps={
        {
          endAdornment: (
            <IconButton
              onClick={() => setShowPassword(!showPassword)}>
              {showPassword ?
                <Visibility style={{ color: coloricon ? coloricon : '#828282' }} /> :
                <VisibilityOff style={{ color: coloricon ? coloricon : '#828282' }} />
              }
            </IconButton>
          ),
        }
      }
      helperText={!!errorText ? errorText : ''}
      {...reset}
    />
  );
}


// InputPassword.propTypes = {
//   type: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
//   placeholder: PropTypes.object.isRequired,
//   customClasses: PropTypes.object,
// };

export default InputPassword;
