import React from 'react';
import { IntlProvider } from 'react-intl';
import { useAppContext } from '../AppContextProvider';

import enMessages from './messages/en';
import arMessages from './messages/ar';

const allMessages = {
  en: enMessages,
  ar: arMessages,
};

export function I18nProvider({ children }) {
  const { appContextObj } = useAppContext();
  const messages = allMessages[appContextObj.locale];

  return (
    <IntlProvider locale={appContextObj.locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
