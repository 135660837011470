import React from "react";
import * as Styled from "./styled";
import * as Images from "~/images";
import * as Comp from "../../../../../Components/Form";
import { useSelector } from "react-redux";
import { POST_REQUEST } from "~/HooksApi/useApi";
import { USeInsertDataWithImage, USeAddPayment } from "~/HookAxios/postData";
import { CircularProgress } from "@mui/material";
import LoaderSubmit from "../../../../../Components/Loader/lotile/loaderSubmit/LoaderSubmit";
import ErrorLoader from "../../../../../Components/Loader/lotile/errorLoader/ErrorLoader";
import { useModalContext } from "../../../../../Provider/ModalProvider";
import { useNavigate } from "react-router-dom";
const PayPointsConfirm = () => {
  const { Closepaypoints } = useModalContext();
  const points = useSelector((state) => state.PayPointsSlice.points);
  const payments = useSelector((state) => state.PayPointsSlice.payments);
  const token = useSelector((state) => state.Auth.userInfo.token);
  const [Imge, setImge] = React.useState(Images.cameraIcon);
  const [Status, setStatus] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState("");
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  console.log("the data is sdfsdfsd", points, payments)
  const navigate = useNavigate();
  const {
    mutate: PayRequest,
    status: StatusPayRequest,
    data: dataPayRequest,
    error: errorPayRequest,
  } = POST_REQUEST({
    request: (data) =>
      USeInsertDataWithImage("add_points_subscriptions", data, {
        Authorization: `Bearer ${token}`,
      }),
    handelOnSettledError,
    handelOnSettledData,
  });
  const {
    mutate: AddPayment,
    status: StatusAddPaymentRequest,
    data: dataAddPaymentRequest,
    error: errorAddPaymentRequest,
  } = POST_REQUEST({
    request: (data) =>
      USeAddPayment("create_payment", data, {
        Authorization: `Bearer ${token}`,
      }),
    handelOnSettledError,
    handelOnSettledData,
  });
  function handelOnSettledError(error) {
    setStatus(false);
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  }
  function handelOnSettledData(data) {
    setStatus(false);
    setSuccess(true);
    setTimeout(() => {
      window.open(data?.data?.data?.webviewurl,'_blank');
      // navigate("/");
    }, 1000);
  }
  const handelClick = () => {
    
      setError(false);
      setStatus(true);
      // const formData = new FormData();
      // formData.append("points_id", points.id);
      // formData.append("payment_type", payments.id);
      // formData.append("image", selectedFile);
      const formData = {
        payment_type:payments.id,
        points_id:points.id,
        amount:points.price,
        status:"0"
      }
      AddPayment(formData);
    
  };
  const changeImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImge(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
    setError(false);
  };


  return (
    <Styled.MsgParents>
      <Styled.MsgText style={{ alignSelf: "center", fontSize:'1.3em' }}>
        
        {/* <Styled.MsgTextBoldBlue> */}
          شراء ({points?.number_of_points}) نقطة بمبلغ ({points?.price}) ل.س عن طريق {payments?.name} ؟
        {/* </Styled.MsgTextBoldBlue> */}
        
      </Styled.MsgText>
      {/* <Styled.MsgText>
        يرجى الذهاب إلى أحد مراكز الهرم وإرسال مبلغ قدره {points?.price} ل.س على
        البيانات التالية | الاسم الثلاثي للمرسل إليه:
        <Styled.MsgTextBold>{payments.receiver_name} </Styled.MsgTextBold>، رقم
        هاتفه:
        <Styled.MsgTextBold>{payments.phone_number}</Styled.MsgTextBold>
      </Styled.MsgText>
      <Styled.MsgText style={{ alignSelf: "start" }}>
        ثم أرسل وصل الإرسال هنا
      </Styled.MsgText>
      <Styled.CompImage>
        <label htmlFor="upload-photo" style={{ cursor: "pointer" }}>
          {selectedFile ? (
            <Styled.Image src={Imge} />
          ) : (
            <img src={Imge} alt="" />
          )}
        </label>
        <input
          type="file"
          id="upload-photo"
          onChange={changeImage}
          style={{ display: "none" }}
        />
      </Styled.CompImage>
      {error ? (
        <span style={{ color: "red" }}>يرجى ادخال صورة الحوالة </span>
      ) : (
        ""
      )}

      {success ? (
        <LoaderSubmit style={{ width: "80px" }} />
      ) : error ? (
        <ErrorLoader style={{ width: "80px" }} />
      ) : Status ? (
        <CircularProgress sx={{ alignSelf: "center" }} />
      ) : (
        <Comp.MuiButton width="90%" text="شراء" onClick={handelClick} />
      )} */}
      <Comp.MuiButton width="90%" text="متابعة" onClick={handelClick} />
    </Styled.MsgParents>
  );
};

export default PayPointsConfirm;
