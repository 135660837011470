import React from 'react'
import { HederSection, HederText, Image, LeftSection, NameQuest, QuestComp, QuestDetails, QuestText, QuestsComp, RightSection, Section } from './StyledQuestions'
import { quest, User, } from './data/images'
import { InputFail } from '../../../Components/Form';
import inputSearchProps from './data/inputProps';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowRight } from '../../../images';
const Question = () => {
  return (
    <Section>
      <RightSection>
        <HederSection>
          <IconButton onButton  >
            <Link to={- 1} >
              <img src={ArrowRight} alt="" />
            </Link>
          </IconButton>
          <HederText>
            الاستفسارات
          </HederText>
        </HederSection>
        <QuestsComp>
          <QuestComp>
            <img src={User} alt="" />
            <QuestText>
              <NameQuest>
                محمد جميل
              </NameQuest>
              <QuestDetails>
                تظل الفلسفات التربوية وأهدافها مجردات ما لم تجد طريقها إلى المناهج التعليمية التي تعمل على ترجمتها إلى أداءات ملموسة، ذلك أن المناهج التعليمية الحديثة أصبحت؟
              </QuestDetails>
              <NameQuest>
              </NameQuest>
            </QuestText>
          </QuestComp>
          <QuestComp>
            <img src={User} alt="" />
            <QuestText>
              <NameQuest>
                محمد جميل
              </NameQuest>
              <QuestDetails>
                تظل الفلسفات التربوية وأهدافها مجردات ما لم تجد طريقها إلى المناهج التعليمية التي تعمل على ترجمتها إلى أداءات ملموسة، ذلك أن المناهج التعليمية الحديثة أصبحت؟
              </QuestDetails>
              <NameQuest>
              </NameQuest>
            </QuestText>
          </QuestComp>
          <QuestComp>
            <img src={User} alt="" />
            <QuestText>
              <NameQuest>
                محمد جميل
              </NameQuest>
              <QuestDetails>
                تظل الفلسفات التربوية وأهدافها مجردات ما لم تجد طريقها إلى المناهج التعليمية التي تعمل على ترجمتها إلى أداءات ملموسة، ذلك أن المناهج التعليمية الحديثة أصبحت؟
              </QuestDetails>
              <NameQuest>
              </NameQuest>
            </QuestText>
          </QuestComp>
          <QuestComp>
            <img src={User} alt="" />
            <QuestText>
              <NameQuest>
                محمد جميل
              </NameQuest>
              <QuestDetails>
                تظل الفلسفات التربوية وأهدافها مجردات ما لم تجد طريقها إلى المناهج التعليمية التي تعمل على ترجمتها إلى أداءات ملموسة، ذلك أن المناهج التعليمية الحديثة أصبحت؟
              </QuestDetails>
              <NameQuest>
              </NameQuest>
            </QuestText>
          </QuestComp>
        </QuestsComp>
        <InputFail
          width="50vw"
          placeholder='أدخل استفسارك هنا... '
          inputProps={inputSearchProps}
          borderradius='5px'
        />
      </RightSection>
      <LeftSection>
        <Image src={quest} />
      </LeftSection>
    </Section>
  )
}

export default Question