import React from "react";
import {
  Content,
  Header,
  Heading,
  Form,
  BarContentForm,
  DivedForm,
  IconsForm,
  Icon,
  TextLink,
  Text,
  InputContainer,
  InputText,
  LinkTo,
} from "./StyledAuth";
import { close } from "./images";
import { IconButton, Backdrop, Modal, Zoom, Box } from "@mui/material";
const BasicModal = ({
  Close,
  open,
  title,
  children,
  text,
  textlink,
  linkto,
  opento,
  textopento,
  buttonText,
  textopentow,
  opentotwo,
  onSubmit,
}) => {
  return (
    <Modal
      open={open}
      onClose={Close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        m: "auto",
        transition: "opacity 2000ms ease-in-out",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // maxHeight: "70vh",
      }}
    >
      <Zoom in={open}>
        <Content>
          <Header>
            <Heading>{title}</Heading>
            <IconButton
              style={{ position: "absolute", right: "0" }}
              onClick={Close}
            >
              <img src={close} alt="/" />
            </IconButton>
          </Header>
          <Box sx={{ width: "100%", overflowY: 'auto', maxHeight: "70vh" }}>
            {children}
          </Box>
          <Text>
            {text}
            <TextLink onClick={linkto}>{textlink} </TextLink>
          </Text>
        </Content>
      </Zoom>
    </Modal>
  );
};

export default BasicModal;
