import { Avatar, Typography, styled } from "@mui/material";

export const MsgParents = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap:'20px'
}))
export const Image = styled(Avatar)(({ theme }) => ({
    width: '48px',
    height: '48px'
}))
export const TextMassage = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    fontWeight: '400',
    lineHeight: "20px",
    color: theme.palette.mode === "light" ? theme.palette.text.lightSecondary : theme.palette.text.darkSecondary
}))