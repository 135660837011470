import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .test({
      name: "phone",
      exclusive: true,
      message: "رقم الهاتف غير صالح",
      test: (value) => {
        if (value?.startsWith("+963")) {
          const phone = value?.split(" ")?.join("");
          const regex = /^(!?(\+?963))0?9\d{8}$/;
          const found = phone?.match(regex);
          if (found !== null) return true;
          else return false;
        } else return true;
      },
    })
    .required("يرجى ادخال رقم الهاتف  "),
  password: Yup.string()
    .required(<FormattedMessage id={`Auth.passwordValidations`} />)
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*.-]).{8,}$/,
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
      " يجب أن تتكون كلمة السر من ثمانية أحرف على الاقل وباللغة الانكليزية وأن تتضمن أحرف كبيرة مثل(ABC) وأحرف صغيرة مثل (abc) وأحرف خاصة مثل (!@#$%^*.) وأرقام مثل (123)"      // , <FormattedMessage id={`Auth.passwordValidationsThree`} />
    )
    .min(8, <FormattedMessage id={`Auth.passwordValidationsTwo`} />),
});
export const formikProps = {
  validationSchema,
  formik: {
    onChangeValidate: false,
    onBlurValidate: false,
  },
  initialValues: {
    phone: "",
    password: "",
  },
};
