import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    password: Yup.string().required(
        <FormattedMessage id={`Auth.passwordValidations`} />
    ).matches(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*.-]).{8,}$/
        , ' يجب  ان يكون بالغة الإنجليزية ويحتوي على محرف كبير ومحرف صغير و رمز خاص واحد على الاقل'
    ).min(8, <FormattedMessage id={`Auth.passwordValidationsTwo`} />)
    ,
    confirm_password: Yup.string().required("هذا الحقل مطلوب")
        .oneOf([Yup.ref('password'), null], '  غير مطابق مع كلمة السر ')
});
export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialValues: {
        password: '',
        confirm_password: ''
    },
};