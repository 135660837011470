export const links = [
    {
        id: 1,
        name: 'الرئيسة',
        link: '/'
    },
    // {
    //     id: 2,
    //     name: 'شراء نقاط',
    //     link: '/pay-points'
    // },
    {
        id: 3, 
        name: 'حول منصة طالب',
        link: '/about-us'
    },
]